import { Outlet, useLocation } from 'react-router-dom';
import { styled, Text } from '@grupoboticario/flora-react';
import { useEffect } from 'react';
import { useFiltersDispatch } from '@/features';

const PageContainer = styled('div', {
  maxWidth: '1360px',
  marginInline: 'auto',
  padding: '$6',
});

export function PageLayout() {
  const { pathname } = useLocation();
  const dispatch = useFiltersDispatch();

  useEffect(() => {
    dispatch({
      type: 'SET_TIME_SERIES',
      payload: false,
    });
  }, [pathname]);

  return (
    <PageContainer>
      <Outlet />
      <Text as="p" size="auxiliarMini" color="$nonInteractiveAuxiliar" css={{ marginBlock: '$6 0' }}>
        Os valores exibidos são contabilizados, individualmente, por setor. Quando filtrado somente um Franqueado ou
        Espaço do Revendedor, então os valores dos seus respectivos setores são todos somados para compor os resultados.
      </Text>
    </PageContainer>
  );
}
