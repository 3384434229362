import { createContext, useContext, useReducer } from 'react';

export const FiltersContext = createContext(null);
export const FiltersDispatchContext = createContext(null);

type StructureType = 'region' | 'uf' | 'cp' | 'cs' | 'sector';

type Model = 'FVC' | 'CAMPO' | 'SELECTOR';

interface Structure {
  code: string;
  name: string;
  type: StructureType;
  model?: Model;
  parents?: StructureFilterOption[];
}

interface Cycle {
  code: string;
  name: string;
}

interface FilterOption {
  selected: boolean;
  enabled: boolean;
}

interface SalesForce {
  name: string;
  model: Model;
}

interface CycleFilterOption extends FilterOption, Cycle {}
export interface StructureFilterOption extends FilterOption, Structure {}
interface SalesForceOption extends FilterOption, SalesForce {}

interface FilterState {
  cycles: CycleFilterOption[];
  structures: { FVC: StructureFilterOption[]; CAMPO: StructureFilterOption[] };
  salesForce: SalesForceOption[];
  selectedCycles: string[];
  selectedSectorCodes: string[];
  salesForceModel: Model;
  canViewSelector: boolean;
  isTimeSeries: boolean;
  isFirstRequest: boolean;
  requestData: { cycle: string; sector: string; model: Model };
  hasChanges: boolean;
  lastSelected: StructureType;
}

type SetCyclesAction = {
  type: 'SET_CYCLES';
  payload: Cycle[];
};

type SelectCycleAction = {
  type: 'SELECT_CYCLE';
  payload: string[];
};

type SetStructuresAction = {
  type: 'SET_FILTERS';
  payload: { structures: { FVC: Structure[]; CAMPO: Structure[] }; model: Model };
};

type SelectFilterAction = {
  type: 'SELECT_FILTER';
  payload: {
    structureType: StructureType;
    selectedCodes: string[];
  };
};

type ClearFiltersSelectionAction = {
  type: 'CLEAR_FILTERS_SELECTION';
};

type SelectAllFilterAction = {
  type: 'SELECT_ALL_OPTIONS';
  payload: StructureType;
};

type ToggleSalesForceAction = {
  type: 'TOGGLE_SALES_FORCE';
};

type SetTimeSeriesCycle = {
  type: 'SET_TIME_SERIES';
  payload: boolean;
};

type SetRequestDataAction = {
  type: 'SET_REQUEST_DATA';
  payload: boolean;
};

type DiscardChangesAction = {
  type: 'DISCARD_CHANGES';
};

type setIsFirstRequestAction = {
  type: 'SET_IS_FIRST_REQUEST';
  payload: boolean;
};

type FilterActions =
  | SetCyclesAction
  | SelectCycleAction
  | SetTimeSeriesCycle
  | SetStructuresAction
  | SelectFilterAction
  | ClearFiltersSelectionAction
  | SelectAllFilterAction
  | ToggleSalesForceAction
  | SetRequestDataAction
  | DiscardChangesAction
  | setIsFirstRequestAction;

const initialState: FilterState = {
  cycles: [],
  structures: { FVC: [], CAMPO: [] },
  salesForce: [
    { name: 'Força de Vendas Local', model: 'CAMPO', selected: false, enabled: false },
    { name: 'Força de Vendas Central', model: 'FVC', selected: false, enabled: false },
  ],
  selectedCycles: null,
  selectedSectorCodes: null,
  salesForceModel: null,
  canViewSelector: false,
  isTimeSeries: false,
  isFirstRequest: true,
  requestData: { cycle: null, sector: null, model: null },
  hasChanges: false,
  lastSelected: null,
};

function getRelatedStructures(codes, type: StructureType, structures: StructureFilterOption[]) {
  if (type === 'sector') {
    return structures.filter(sector => codes.includes(sector.code));
  }
  return structures.filter(sector =>
    sector.parents.some(parent => parent.type === type && codes.includes(parent.code)),
  );
}

function getCodesInSelectedRegionAndUfs(structures: StructureFilterOption[]) {
  const selectedRegionAndUfCodes = new Set(
    structures.flatMap(item =>
      item.parents
        .filter(parent => (parent.type === 'uf' || parent.type === 'region') && parent.selected)
        .map(parent => parent.code),
    ),
  );

  const codesInSelectedRegionAndUfs = new Set(
    structures.flatMap(item => {
      if (item.parents.some(parent => selectedRegionAndUfCodes.has(parent.code))) {
        return [item.code, ...item.parents.map(parent => parent.code)];
      }
      return [];
    }),
  );
  return Array.from(codesInSelectedRegionAndUfs);
}

function getRelatedStructuresTypes(structureType: StructureType) {
  const hierarchy: StructureType[] = ['region', 'uf', 'cp', 'cs', 'sector'];

  const index = hierarchy.indexOf(structureType);

  const parentsTypes = hierarchy.slice(0, index) ?? [];
  const childrenTypes = hierarchy.slice(index + 1) ?? [];

  return { parentsTypes, childrenTypes };
}

function updateSelection(structures, relatedSectorCodes, relatedParentCodes, structureType) {
  return structures.map(item => {
    return {
      ...item,
      selected: relatedSectorCodes.includes(item.code),
      enabled: item.type === structureType ? item.enabled : relatedSectorCodes.includes(item.code),
      parents: item.parents.map(parent => ({
        ...parent,
        selected: relatedParentCodes.includes(parent.code),
        enabled: parent.type === structureType ? parent.enabled : relatedParentCodes.includes(parent.code),
      })),
    };
  });
}

function mapStructures(structures, selected) {
  return structures?.map(structure => ({
    ...structure,
    selected,
    enabled: selected,
    parents: structure?.parents.map(parent => ({
      ...parent,
      selected,
      enabled: selected,
    })),
  }));
}

function reducer(state: FilterState, action: FilterActions) {
  switch (action.type) {
    case 'SET_CYCLES': {
      const latestCycleCode = action.payload[0].code;

      state.cycles = action.payload.map(cycle => ({
        ...cycle,
        selected: latestCycleCode === cycle.code,
        enabled: true,
      }));

      state.selectedCycles = [latestCycleCode];

      return {
        ...state,
      };
    }
    case 'SELECT_CYCLE': {
      state.hasChanges = true;
      const selectedCycles = action.payload;

      state.cycles = state.cycles.map(cycle => ({
        ...cycle,
        selected: selectedCycles.includes(cycle.code),
      }));

      state.selectedCycles = selectedCycles;

      return { ...state };
    }
    case 'SET_TIME_SERIES': {
      const isTimeSeries = action.payload;
      state.isFirstRequest = true;

      if (!isTimeSeries) {
        if (state.cycles.length === 0) return { ...state, isTimeSeries };
        const latestCycleCode = state.selectedCycles[0];

        state.cycles = state.cycles.map(cycle => ({
          ...cycle,
          selected: latestCycleCode === cycle.code,
        }));

        state.selectedCycles = [latestCycleCode];

        const model = state.salesForce.find(item => item.selected).model;
        const cycle = latestCycleCode;
        const sector = state.structures[state.salesForceModel]
          .filter(item => item.selected)
          .map(item => item.code)
          .join(',');

        state.requestData = { model, cycle, sector };

        return { ...state, isTimeSeries };
      }

      const selectedCycleIndex = state.cycles
        .map(item => item.code)
        .findIndex(item => state.selectedCycles.includes(item));

      const initialLastIndexSelected = selectedCycleIndex + 3;
      const isNearEnd = selectedCycleIndex >= state.cycles.length - 3;

      const initialSelectedCyclesCodes = isNearEnd
        ? state.cycles.slice(-3).map(item => item.code)
        : state.cycles.slice(selectedCycleIndex, initialLastIndexSelected).map(item => item.code);

      state.cycles = state.cycles.map(cycle => ({
        ...cycle,
        selected: initialSelectedCyclesCodes.includes(cycle.code),
        enabled: true,
      }));
      const selectedCycles = state.cycles.filter(item => item.selected).map(item => item.code);
      state.selectedCycles = selectedCycles;

      const model = state.salesForce.find(item => item.selected).model;
      const cycle = selectedCycles.join(',');
      const sector = state.structures[state.salesForceModel]
        .filter(item => item.selected)
        .map(item => item.code)
        .join(',');

      state.requestData = { model, cycle, sector };

      return { ...state, isTimeSeries };
    }
    case 'SET_FILTERS': {
      const { structures, model: userModel } = action.payload;
      state.canViewSelector = userModel === 'SELECTOR';

      const hasFvc = structures.FVC.length > 0;
      const hasFvl = structures.CAMPO.length > 0;

      state.salesForce = state.salesForce.map(item => ({
        ...item,
        selected:
          userModel === 'SELECTOR'
            ? (hasFvc && hasFvl) || hasFvl || !hasFvc
              ? item.model === 'CAMPO'
              : item.model === 'FVC'
            : item.model === userModel,
        enabled:
          userModel === 'SELECTOR'
            ? hasFvl && hasFvc
              ? true
              : hasFvc
                ? item.model === 'FVC'
                : item.model === 'CAMPO'
            : item.model === userModel,
      }));

      const selectedSalesForce = state.salesForce.find(item => item.selected).model;
      const isFvcSelected = selectedSalesForce === 'FVC';

      state.structures = {
        FVC: mapStructures(structures?.FVC, isFvcSelected),
        CAMPO: mapStructures(structures?.CAMPO, !isFvcSelected),
      };

      state.selectedSectorCodes = state.structures[selectedSalesForce]
        .filter(item => item.selected)
        .map(item => item.code);

      state.salesForceModel = selectedSalesForce;

      return {
        ...state,
      };
    }
    case 'SELECT_FILTER': {
      const { selectedCodes, structureType } = action.payload;
      const { parentsTypes, childrenTypes } = getRelatedStructuresTypes(structureType);
      state.hasChanges = true;
      state.lastSelected = structureType;

      const salesForceModel = state.salesForceModel;

      const noneSelectedInStructure = selectedCodes.length === 0;
      const relatedStructures = getRelatedStructures(selectedCodes, structureType, state.structures[salesForceModel]);

      const relatedSectorCodes = relatedStructures.map(item => item.code);
      const relatedParentCodes = relatedStructures.flatMap(item => item.parents.map(parent => parent.code));

      if (noneSelectedInStructure) {
        state.structures[salesForceModel] = state.structures[salesForceModel].map(item => {
          return {
            ...item,
            selected: relatedSectorCodes.includes(item.code),
            enabled: true,
            parents: item.parents.map(parent => ({
              ...parent,
              selected: parentsTypes.includes(parent.type) ? parent.selected : relatedParentCodes.includes(parent.code),
              enabled: childrenTypes.includes(parent.type)
                ? true
                : parent.type === structureType
                  ? true
                  : parent.enabled,
            })),
          };
        });

        state.selectedSectorCodes = state.structures[salesForceModel]
          .filter(item => item.selected)
          .map(item => item.code);
        return { ...state };
      }

      if (structureType === 'cp') {
        const codesInSelectedRegionAndUfs = getCodesInSelectedRegionAndUfs(state.structures[salesForceModel]);

        const isAllSelectedCpInSelectedRegionAndUfs = selectedCodes.every(code =>
          codesInSelectedRegionAndUfs.includes(code),
        );

        const lastAddedItem = selectedCodes[selectedCodes.length - 1];

        const lastAddedItemStructure = getRelatedStructures(
          [lastAddedItem],
          structureType,
          state.structures[salesForceModel],
        );

        const lastAddedItemSectorCodes = lastAddedItemStructure.map(item => item.code);
        const lastAddedItemParentsCodes = lastAddedItemStructure.flatMap(item =>
          item.parents.map(parent => parent.code),
        );
        const lastAddedItemCodes = [...lastAddedItemSectorCodes, ...lastAddedItemParentsCodes];

        const isLastItemInSelectedRegionOrUf = lastAddedItemParentsCodes.some(parentCode =>
          codesInSelectedRegionAndUfs.includes(parentCode),
        );

        const isSelected = (code, relatedCodes) => {
          if (isAllSelectedCpInSelectedRegionAndUfs) {
            return codesInSelectedRegionAndUfs.includes(code) && relatedCodes.includes(code);
          }
          if (!isLastItemInSelectedRegionOrUf) {
            return (
              lastAddedItemCodes.includes(code) ||
              (codesInSelectedRegionAndUfs.includes(code) && relatedCodes.includes(code))
            );
          }
          return relatedCodes.includes(code);
        };

        state.structures[salesForceModel] = state.structures[salesForceModel].map(item => ({
          ...item,
          selected: isSelected(item.code, relatedSectorCodes),
          enabled: isSelected(item.code, relatedSectorCodes),
          parents: item.parents.map(parent => ({
            ...parent,
            selected:
              parent.type === 'cp' ? selectedCodes.includes(parent.code) : isSelected(parent.code, relatedParentCodes),
            enabled: parent.type === structureType ? parent.enabled : isSelected(parent.code, relatedParentCodes),
          })),
        }));

        state.selectedSectorCodes = state.structures[salesForceModel]
          .filter(item => item.selected)
          .map(item => item.code);

        return { ...state };
      }

      state.structures[salesForceModel] = updateSelection(
        state.structures[salesForceModel],
        relatedSectorCodes,
        relatedParentCodes,
        structureType,
      );

      state.selectedSectorCodes = state.structures[salesForceModel]
        .filter(item => item.selected)
        .map(item => item.code);

      return { ...state };
    }
    case 'CLEAR_FILTERS_SELECTION': {
      const salesForceModel = state.salesForce.find(item => item.selected)?.model;
      state.hasChanges = true;

      state.structures[salesForceModel] = state.structures[salesForceModel]?.map(item => ({
        ...item,
        selected: false,
        enabled: true,
        parents: item.parents.map(parent => ({ ...parent, selected: false, enabled: true })),
      }));

      state.selectedSectorCodes = [];

      return {
        ...state,
      };
    }
    case 'SELECT_ALL_OPTIONS': {
      const { payload: structureType } = action;
      const { childrenTypes } = getRelatedStructuresTypes(structureType);
      state.hasChanges = true;

      const salesForceModel = state.salesForce.find(item => item.selected).model;

      const allEnabledOptionsSelected =
        structureType === 'sector'
          ? state.structures[salesForceModel].filter(item => item.enabled).every(item => item.selected)
          : state.structures[salesForceModel].every(item =>
              item.parents.filter(item => item.enabled && item.type === structureType).every(item => item.selected),
            );

      const selectedStrucureEnabledCodes =
        structureType === 'sector'
          ? state.structures[salesForceModel].filter(item => item.enabled).map(item => item.code)
          : state.structures[salesForceModel].flatMap(item =>
              item.parents.filter(item => item.enabled && item.type === structureType).map(item => item.code),
            );

      const relatedItems = getRelatedStructures(
        selectedStrucureEnabledCodes,
        structureType,
        state.structures[salesForceModel],
      );

      const relatedSectorCodes = relatedItems.map(item => item.code);
      const relatedParentsCodes = relatedItems.flatMap(item => item.parents.map(parent => parent.code));

      const relatedStructuresCodes = [...relatedSectorCodes, ...relatedParentsCodes];

      const selectedRegionAndUfs = getCodesInSelectedRegionAndUfs(state.structures[salesForceModel]);

      const isItemSelected = item => {
        if (allEnabledOptionsSelected) {
          if (structureType === item.type) {
            return false;
          }
          if (childrenTypes.includes(item.type)) {
            return false;
          }
          return item.enabled && item.selected;
        }

        if (structureType === 'cp') {
          return (
            (selectedRegionAndUfs.includes(item.code) || selectedRegionAndUfs.length === 0) &&
            relatedStructuresCodes.includes(item.code)
          );
        }

        if (structureType === item.type) {
          return item.enabled;
        }

        return relatedStructuresCodes.includes(item.code);
      };

      const isItemEnabled = item => {
        if (allEnabledOptionsSelected) {
          if (childrenTypes.includes(item.type)) {
            return true;
          }
        }
        if (structureType === 'cp') {
          return (
            (selectedRegionAndUfs.includes(item.code) || selectedRegionAndUfs.length === 0) &&
            relatedStructuresCodes.includes(item.code)
          );
        }

        return relatedStructuresCodes.includes(item.code);
      };

      state.structures[salesForceModel] = state.structures[salesForceModel].map(item => {
        return {
          ...item,
          selected: isItemSelected(item),
          enabled: isItemEnabled(item),
          parents: item.parents.map(parent => ({
            ...parent,
            selected: isItemSelected(parent),
            enabled: isItemEnabled(parent),
          })),
        };
      });

      state.selectedSectorCodes = state.structures[salesForceModel]
        .filter(item => item.selected)
        .map(item => item.code);

      return {
        ...state,
      };
    }
    case 'TOGGLE_SALES_FORCE': {
      state.hasChanges = true;

      const updatedSalesForceSelection = state.salesForce.map(item => ({
        ...item,
        selected: !item.selected,
      }));

      const isFvcSelected = updatedSalesForceSelection.find(item => item.selected).model === 'FVC';

      state.structures = {
        FVC: mapStructures(state.structures.FVC, isFvcSelected),
        CAMPO: mapStructures(state.structures.CAMPO, !isFvcSelected),
      };

      state.salesForce = updatedSalesForceSelection;

      state.salesForceModel = state.salesForce.find(item => item.selected).model;

      state.selectedSectorCodes = state.structures[state.salesForceModel]
        .filter(item => item.selected)
        .map(item => item.code);

      return { ...state };
    }
    case 'SET_REQUEST_DATA': {
      const model = state.salesForce.find(item => item.selected).model;
      const selectedCycles = state.cycles.filter(item => item.selected).map(item => item.code);
      const cycle = selectedCycles.length === 1 ? selectedCycles[0] : selectedCycles.join(',');
      const sector = state.structures[state.salesForceModel]
        .filter(item => item.selected)
        .map(item => item.code)
        .join(',');

      state.requestData = { model, cycle, sector };

      state.hasChanges = false;

      return { ...state };
    }
    case 'DISCARD_CHANGES': {
      const { model, cycle, sector } = state.requestData;
      const modelForRequest = model.toString();
      const cyclesForRequest = typeof cycle === 'number' ? [cycle] : cycle.split(',').map(item => item);
      const sectorsForRequest = sector.split(',').map(item => item);

      const { selectedCycles, selectedSectorCodes, salesForceModel } = state;

      const hasChanges = (dataFromRequest, selectedItems) => {
        if (dataFromRequest.length !== selectedItems.length) {
          return true;
        }

        return dataFromRequest.some((item, index) => Number(item) !== Number(selectedItems[index]));
      };

      if (
        hasChanges(sectorsForRequest, selectedSectorCodes) ||
        hasChanges(cyclesForRequest, selectedCycles) ||
        modelForRequest !== salesForceModel
      ) {
        const updatedSelectedCycles = cyclesForRequest.map(code => Number(code));
        state.cycles = state.cycles.map(cycle => ({
          ...cycle,
          selected: updatedSelectedCycles.includes(Number(cycle.code)),
        }));
        const updatedSelectedSectors = sectorsForRequest.map(code => Number(code));
        const relatedItems = getRelatedStructures(updatedSelectedSectors, 'sector', state.structures[salesForceModel]);
        const relatedSectorCodes = relatedItems.map(item => item.code);
        const relatedParentsCodes = relatedItems.flatMap(item => item.parents.map(parent => parent.code));

        state.structures[salesForceModel] = updateSelection(
          state.structures[salesForceModel],
          relatedSectorCodes,
          relatedParentsCodes,
          state.lastSelected,
        );

        state.salesForce = state.salesForce.map(item => ({
          ...item,
          selected: item.model === modelForRequest,
        }));
        state.selectedCycles = state.cycles.filter(item => item.selected).map(item => item.code);
        state.selectedSectorCodes = sectorsForRequest;
        state.salesForceModel = model;
      }

      state.hasChanges = false;
      return { ...state };
    }
    case 'SET_IS_FIRST_REQUEST': {
      state.isFirstRequest = action.payload;
      return { ...state };
    }
  }
  // @ts-expect-error always throw error when unknown action
  throw Error('Unknown action: ' + action.type);
}

export function useFilters() {
  return useContext<FilterState>(FiltersContext);
}

export function useFiltersDispatch() {
  return useContext(FiltersDispatchContext);
}

export function FiltersProvider({ children }) {
  const [filters, dispatch] = useReducer(reducer, initialState);
  return (
    <FiltersContext.Provider value={filters}>
      <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  );
}
