import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const levelThreeGridSettings = (hasThirdLevel: boolean): DashboardGridProps['css'] => {
  return {
    gap: '$4',
    '@mobile': {
      gridTemplateAreas: `
    "level-two-chart"
    "level-three-chart"
    `,
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@tablet': {
      gridTemplateAreas: `
    "level-two-chart level-two-chart"
    "level-three-chart level-three-chart"
    `,
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@desktop': {
      gridTemplateAreas: `
    "level-two-chart level-two-chart level-two-chart ${hasThirdLevel ? 'level-three-chart' : 'level-two-chart'}"
    `,
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  };
};
