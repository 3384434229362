import { Card, CardTitle, ChartLegend, ReactECharts, defaultColorPalette } from '@/shared/components';
import { setOptions, setChartsSeries, setTooltip, percentNoFraction } from '@/shared/format';

import { memo } from 'react';

const colors = [defaultColorPalette['lightGreen'], defaultColorPalette['lightBlue']];
const columnsCustomOrder = ['Ativos pelo total da base', 'Ativos pelo total disponível'];
const cardTitle = 'Percentual de Revendedores Ativos - FV';

export const ActiveResellersChart = memo<{ isLoading: boolean; chart: Record<string, never> }>(
  function ActiveResellersChart({ isLoading, chart }) {
    const formattedValue = columnsCustomOrder.map((column, index) => {
      const percentage = column === 'Ativos pelo total da base' ? chart?.byTotal : chart?.byAvailable;

      return {
        name: column,
        percentage,
        code: index,
      };
    });

    return (
      <Card css={{ gridArea: 'active-resellers-chart' }}>
        <CardTitle
          title={cardTitle}
          tooltip="Percentual de revendedores ativos, gerenciados pela Força de Vendas, sobre o total da Base Ativa - FV e sobre a Base Disponível"
        />
        <ReactECharts
          loading={isLoading}
          option={{
            ...setOptions({ colors, formatter: percentNoFraction.format }),
            series: setChartsSeries({ columns: formattedValue, columnsCustomOrder }),
            tooltip: setTooltip(cardTitle),
          }}
          style={{ minWidth: '100%', height: '320px' }}
        />
        <ChartLegend columns={formattedValue} colors={colors} />
      </Card>
    );
  },
);
