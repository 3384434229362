import { memo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Flex, Button } from '@grupoboticario/flora-react';
import { ReactECharts, defaultColorPalette, ChartLegend } from '@/shared/components';
import { setChartsSeries, setTooltip, toSnakeCase, setOptions } from '@/shared/format';
import { useResellersServiceNavigationDispatch } from '../context/Navigation';
import { ChartTitle } from './ChartTitle';
import { useEventTracker } from '@/shared/hooks';

const colors = [
  defaultColorPalette['lightGreen'],
  defaultColorPalette['orange'],
  defaultColorPalette['lightBlue'],
  defaultColorPalette['red'],
  defaultColorPalette['blue'],
];

const columnsCustomOrder = ['Venda', 'Recusa de Venda', 'Solicitações da RE', 'Reclamações', 'Agendamento'];

export const TabulationChartLevelTwo = memo<{ isLoading: boolean; chart; hasChild: boolean; tooltipText: string }>(
  function TabulationChartLevelTwo({ isLoading, chart, hasChild, tooltipText }) {
    const dispatch = useResellersServiceNavigationDispatch();
    const { clickedOnTabulationChart } = useEventTracker();

    const handleOnClick = event => {
      const currentNavigationName = event.name;
      clickedOnTabulationChart({
        tabulationKind: toSnakeCase(currentNavigationName),
      });
      unstable_batchedUpdates(() => {
        dispatch({ type: 'SET_CHART_COLOR', payload: { chartColor: event.color } });
        dispatch({ type: 'SET_CURRENT_PARENT_ID', payload: { parentIDs: { LVL2: event.data.code } } });
        dispatch({ type: 'SET_CURRENT_NAVIGATION', payload: { setAtLevel: 3, currentNavigation: event.name } });
      });
    };

    const handleBackToHome = () => {
      clickedOnTabulationChart({
        tabulationKind: 'voltar_home',
      });
      dispatch({ type: 'SET_CURRENT_NAVIGATION_HOME' });
    };

    return (
      <Flex direction="column" justify="space-around" gapX="$6" css={{ gridArea: 'level-two-chart', maxWidth: '100%' }}>
        <Flex direction="row" align="center">
          <ChartTitle />
          <Button
            onClick={handleBackToHome}
            size="small"
            hierarchy="tertiary"
            css={{
              marginInlineStart: 'auto',
            }}
          >
            ← Voltar
          </Button>
        </Flex>
        <ReactECharts
          loading={isLoading}
          option={{
            ...setOptions({ colors }),
            series: setChartsSeries({
              columns: chart?.columns,
              columnsCustomOrder,
            }),
            tooltip: setTooltip(`Revendedores por tipo de acionamento - ${tooltipText}`),
          }}
          style={{ width: '100%', height: '320px' }}
          onClick={hasChild ? handleOnClick : undefined}
        />
        <ChartLegend columns={chart?.columns} colors={colors} />
      </Flex>
    );
  },
);
