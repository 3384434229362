import { Select } from 'antd';
import { Flex, Text } from '@grupoboticario/flora-react';
import { useEventTracker } from '@/shared/hooks';
import { useFilters, useFiltersDispatch } from '@/features';

const { Option } = Select;

export function FilterCycle({ isLoading }) {
  const dispatch = useFiltersDispatch();
  const { cycles, selectedCycles } = useFilters();
  const { clickedOnFilter } = useEventTracker();

  function handleCycleSelection(value) {
    dispatch({
      type: 'SELECT_CYCLE',
      payload: [value],
    });
  }

  return (
    <Flex direction="column" wrap="wrap" title="cycle-filter" css={{ width: '100%' }}>
      <Text as="label" size="exceptionsRestrictedRegular" htmlFor="cycle">
        Ciclo
      </Text>
      <Select
        showSearch
        disabled={isLoading}
        loading={isLoading}
        style={{ width: '100%' }}
        placeholder="Pesquise ou selecione"
        placement="bottomLeft"
        id="cycle"
        onChange={value => handleCycleSelection(value)}
        filterOption={(input, option) => (`${option?.label}` ?? '').toLowerCase().includes(input.toLowerCase())}
        maxTagCount={1}
        value={selectedCycles}
        onFocus={() => clickedOnFilter({ filterName: 'ciclo' })}
      >
        {cycles?.map(item => {
          if (!item.enabled) return null;

          return (
            <Option
              title={`${item.code} - ${item.name}`}
              key={`${item.code} - ${item.name}`}
              value={item.code}
              label={`${item.code} - ${item.name}`}
            >
              {item.code} - {item.name}
            </Option>
          );
        })}
      </Select>
    </Flex>
  );
}
