import { Routes } from './Routes';
import { Breadcrumb } from './components';
import { ResellersServiceNavigationProvider } from './context/Navigation';

export function ResellersService() {
  return (
    <ResellersServiceNavigationProvider>
      <Breadcrumb />
      <Routes />
    </ResellersServiceNavigationProvider>
  );
}
