import { Select } from 'antd';
import { Flex, Text, Tooltip } from '@grupoboticario/flora-react';

import { useEventTracker } from '@/shared/hooks';

import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';
import { useFilters, useFiltersDispatch } from '@/features';
import { DisabledInput } from '../FilterSelect/DisabledInput';

const { Option } = Select;

export function SalesForceSelect({ isLoading }: { isLoading: boolean }) {
  const dispatch = useFiltersDispatch();
  const { salesForce } = useFilters();
  const { clickedOnFilter } = useEventTracker();

  const salesForceModelName = salesForce.find(item => item.selected)?.name;
  const hasOnlyOneOption = salesForce.filter(item => item.enabled).length === 1;

  function handleOnChange() {
    dispatch({
      type: 'TOGGLE_SALES_FORCE',
    });
  }

  return (
    <Flex direction="column" wrap="wrap" title="fv-filter" css={{ width: '100%' }}>
      <Flex direction="row" align="center" flex-wrap="nowrap">
        <Text as="label" size="exceptionsRestrictedRegular" htmlFor="filter-fv">
          Força de vendas
        </Text>
        <Tooltip
          showArrow
          text="Quando a atuação da Força de Vendas, no ciclo selecionado, foi Híbrida, é possível selecionar a fonte dos resultados entre Local ou Central."
        >
          <QuestionMarkCircleIcon color="$nonInteractiveAuxiliar" size="16" css={{ verticalAlign: 'text-top' }} />
        </Tooltip>
      </Flex>
      {hasOnlyOneOption ? (
        salesForce?.map(item => {
          if (!item.enabled) return null;
          return <DisabledInput key={item.name} text={item.name} />;
        })
      ) : (
        <Select
          showSearch
          disabled={isLoading}
          loading={isLoading}
          style={{ width: '100%' }}
          placeholder="Pesquise ou selecione"
          placement="bottomLeft"
          id="fv"
          onChange={handleOnChange}
          filterOption={(input, option) => (`${option?.label}` ?? '').toLowerCase().includes(input.toLowerCase())}
          maxTagCount={1}
          value={salesForceModelName}
          onFocus={() => clickedOnFilter({ filterName: 'forca_de_vendas' })}
        >
          {salesForce?.map(item => {
            if (!item.enabled) return null;
            return (
              <Option title={item.name} key={item.name} value={item.name} label={item.name}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      )}
    </Flex>
  );
}
