import { useState } from 'react';
import { useEventTracker, useCyclesList, useFiltersList } from '@/shared/hooks';
import { MainDashboardFilters, useFilters, useFiltersDispatch } from '@/features';
import { Drawer } from 'antd';
import { CrossIcon, ThreeLinesHorizontal2Icon } from '@grupoboticario/flora-react-icons';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@grupoboticario/flora-react';

export function FilterDrawer() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useFiltersDispatch();
  const { hasChanges } = useFilters();
  const { clickedOnFilter } = useEventTracker();
  const { isLoading: isLoadingCycles } = useCyclesList();
  const { isLoading: isLoadingFilters } = useFiltersList();

  function applyFilter() {
    dispatch({ type: 'SET_REQUEST_DATA' });
    setIsDrawerOpen(false);
  }

  function discardChanges() {
    setIsModalOpen(false);
    setIsDrawerOpen(false);
    dispatch({ type: 'DISCARD_CHANGES' });
  }

  function handleResetFilters() {
    clickedOnFilter({ filterName: 'limpar_filtro' });
    dispatch({
      type: 'CLEAR_FILTERS_SELECTION',
    });
  }

  const isLoading = isLoadingFilters || isLoadingCycles;

  return (
    <>
      <Button hierarchy="secondary" onClick={() => setIsDrawerOpen(true)}>
        {
          <>
            <ThreeLinesHorizontal2Icon size="16px" css={{ marginRight: '$2' }} />
            Filtros
          </>
        }
      </Button>
      <Drawer
        maskClosable={false}
        closeIcon={false}
        placement="right"
        open={isDrawerOpen}
        keyboard={false}
        onClose={() => setIsModalOpen(true)}
      >
        <Flex
          align="center"
          justify="space-between"
          css={{ borderBottom: '1px rgba(0, 0, 0, 0.15) solid', padding: '$6' }}
        >
          <Text size="subTitleDeskRegular">Filtros</Text>
          <Box
            onClick={() => {
              hasChanges ? setIsModalOpen(true) : setIsDrawerOpen(false);
            }}
          >
            <CrossIcon color="$actionableDefault" />
          </Box>
        </Flex>
        <MainDashboardFilters />
        <Flex
          align="center"
          justify="space-between"
          gap="$2"
          css={{
            borderTop: '1px rgba(0, 0, 0, 0.15) solid',
            padding: '$6 $4',
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}
        >
          <Button
            title="Limpar filtros"
            onClick={handleResetFilters}
            css={{
              width: '100%',
            }}
            size="small"
            hierarchy="secondary"
            disabled={isLoading}
          >
            Limpar filtros
          </Button>
          <Button
            css={{
              width: '100%',
            }}
            size="small"
            hierarchy="primary"
            onClick={applyFilter}
          >
            Aplicar filtros
          </Button>
        </Flex>
      </Drawer>

      <Modal open={isModalOpen}>
        <ModalContent size="small">
          <ModalCloseButton onClick={() => setIsModalOpen(false)} />
          <ModalHeader>Alterações não aplicadas</ModalHeader>
          <ModalBody>
            Você fez alterações nos filtros que ainda não foram salvas. Deseja descartar as alterações ou voltar para
            continuar o filtro?
          </ModalBody>
          <ModalFooter>
            <Flex wrap="wrap" css={{ width: '100%', gap: '$2' }}>
              <Button
                css={{
                  width: '100%',
                }}
                hierarchy="secondary"
                onClick={discardChanges}
              >
                Descartar alterações
              </Button>
              <Button
                css={{
                  width: '100%',
                }}
                hierarchy="tertiary"
                onClick={() => setIsModalOpen(false)}
              >
                Voltar para o filtro
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
