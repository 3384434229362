import { useReactTable, ColumnDef, getCoreRowModel, createColumnHelper, flexRender } from '@tanstack/react-table';
import {
  Card,
  CardTitle,
  StyledTable,
  StyledTd,
  StyledTh,
  ToggleButton,
  TableSkeleton,
  InactivityLevelName,
  GbClubLevelName,
} from '@/shared/components';
import { inactivityLevelLabelMap, revenueTableLabelMap } from '@/shared/format';
import { Flex, ScrollArea, Text } from '@grupoboticario/flora-react';
import { useEffect, useState } from 'react';
import { TableCellText } from './TableCellText';
import clsx from 'clsx';

type ColumnHelperMetaType = {
  isPinned?: boolean;
  isCentralized?: boolean;
  isHidden?: boolean;
};
interface CellData {
  code?: string;
  value: number | string;
  percentage?: number | string;
}

interface TableData {
  classification: string;
  revenue: CellData;
  app: CellData;
  er: CellData;
  fvc: CellData;
  super: CellData;
}

const Table = ({ data, tableType, title, isLoading }) => {
  const [brand, setBrand] = useState('channel');
  const [tableData, setTableData] = useState([]);
  const columnHelper = createColumnHelper<TableData>();

  const columnConfig: string[] = isLoading
    ? ['app', 'er', 'portal', 'fv']
    : Array.from(new Set<string>(data?.flatMap(item => item[brand]?.map(({ code }) => code)))).filter(
        code => code !== undefined,
      );

  function generateColumnsAccessor(code) {
    return columnHelper.accessor(code, {
      header: revenueTableLabelMap[code],
      cell: props => {
        const value = props.getValue() as CellData;
        return (
          <TableCellText
            isCurrency
            displayValue={value?.value}
            percentage={value?.percentage}
            textPercentage={props.row.original.classification}
            isMyGbClubTable={tableType === 'myGbClub'}
          />
        );
      },
    });
  }

  const columnsAccessor = columnConfig.map((code: keyof TableData) => generateColumnsAccessor(code));

  const Columns: ColumnDef<TableData>[] = [
    {
      id: 'classification',
      meta: {
        isHidden: true,
        isPinned: true,
      },
      columns: [
        columnHelper.accessor('classification', {
          header: 'Classificação',
          cell: props => {
            if (tableType === 'inactivity') {
              return (
                <InactivityLevelName levelColor={props.getValue()}>
                  {inactivityLevelLabelMap[props.getValue()] || props.getValue()}
                </InactivityLevelName>
              );
            }
            return <GbClubLevelName props={props} />;
          },
          meta: {
            isPinned: true,
          },
        }),
      ],
    },
    {
      meta: {
        isHidden: true,
        isPinned: true,
      },
      id: 'revenue',
      columns: [generateColumnsAccessor('revenue')],
    },
    {
      header: brand === 'brand' ? 'Receitas por Marcas' : 'Receitas por Meios de Captação',
      meta: {
        isCentralized: true,
      },
      columns: columnsAccessor,
    },
  ];

  useEffect(() => {
    if (data) {
      const tableData = data?.map(item => {
        const channels = {};
        columnConfig.forEach(code => {
          channels[code] = item[brand]?.find(value => value?.code === code);
        });
        return {
          classification: item.code,
          revenue: {
            value: item.revenue?.value,
            percentage: item.revenue?.percentage,
          },
          ...channels,
        };
      });
      setTableData(tableData);
    }
  }, [brand, data]);

  const table = useReactTable<TableData>({
    data: tableData,
    columns: Columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card css={{ gridArea: tableType === 'myGbClub' ? 'my-gb-club-table' : 'inactivity-level-table' }}>
      <Flex
        direction="row"
        align="right"
        justify="space-between"
        css={{
          marginBottom: '$8',
          width: '100%',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '60px',
            width: '100%',
          },
        }}
      >
        <CardTitle title={title} tooltip={title} />
        <ToggleButton
          leftText="Meio de captação"
          onLeftButtonClick={() => setBrand('channel')}
          rightText="Marcas"
          onRightButtonClick={() => setBrand('brand')}
        />
      </Flex>
      <ScrollArea css={{ scrollHeight: '4px' }} orientation="horizontal" type="auto">
        <div style={{ paddingBottom: '20px' }}>
          <StyledTable sticky striped>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const meta: ColumnHelperMetaType = header.column.columnDef.meta;
                    return (
                      <StyledTh
                        style={{
                          padding: '0 $4',
                          zIndex: meta?.isPinned ? 1 : 0,
                          position: meta?.isPinned ? 'sticky' : 'static',
                          left: 0,
                          textAlign: meta?.isCentralized ? 'center' : meta?.isPinned ? 'left' : 'right',
                          minWidth: '200px',
                          height: '64px',
                          borderBottom: meta?.isHidden ? 'none' : '1px solid #ccc',
                        }}
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        <Text className={clsx(isLoading && 'loading-placeholder')}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Text>
                      </StyledTh>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading ? (
                <TableSkeleton columns={6} rows={6} />
              ) : (
                table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      const meta: ColumnHelperMetaType = cell.column.columnDef.meta;
                      return (
                        <StyledTd
                          style={{
                            zIndex: meta?.isPinned ? 1 : 0,
                            position: meta?.isPinned ? 'sticky' : 'static',
                            left: 0,
                            textAlign: meta?.isPinned ? 'left' : 'right',
                            minWidth: '200px',
                            borderBottom: '1px solid #ccc',
                          }}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </StyledTd>
                      );
                    })}
                  </tr>
                ))
              )}
            </tbody>
          </StyledTable>
        </div>
      </ScrollArea>
    </Card>
  );
};

export { Table };
