export const revenueTableLabelMap = {
  revenue: 'Receita',
  appRe: 'App RE',
  er: 'ER',
  portal: 'Portal',
  superFv: 'Super FV',
  boti: 'BOTI',
  oui: 'O.U.I',
  qdb: 'QDB',
  eud: 'EUD',
};

export const meuClubeGbLevelNameMap = {
  diamante: 'Diamantes',
  esmeralda: 'Esmeraldas',
  rubi: 'Rubis',
  platina: 'Platinas',
  ouro: 'Ouros',
  prata: 'Pratas',
  bronze: 'Bronzes',
  novoRevendedor: 'Novos Revendedores',
  indefinido: 'Em atualização',
};

export const inactivityLevelLabelMap = {
  A0: 'Inícios',
  'I7+': 'Novos Revendedores',
};

export const brandsLabelMap = {
  oui: 'O.U.I',
  eud: 'Eudora',
  boti: 'O Boticário',
  qdb: 'Quem Disse Berenice?',
};
