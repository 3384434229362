import { Card, CardTitle, ChartLegend, ReactECharts, defaultColorPalette } from '@/shared/components';
import { setChartsSeries, setTooltip, toSnakeCase, setOptions } from '@/shared/format';
import { unstable_batchedUpdates } from 'react-dom';
import { useResellersServiceNavigationDispatch } from '../context/Navigation';
import { memo } from 'react';
import { useEventTracker } from '@/shared/hooks';

const colors = [
  defaultColorPalette['orange'],
  defaultColorPalette['lightGreen'],
  defaultColorPalette['red'],
  defaultColorPalette['lightBlue'],
];

const columnsCustomOrder = [
  'Acionamento personalizado',
  'Contato efetivo',
  'Contato não efetivo',
  'Consulta sem atendimento',
];

export const TabulationChartLevelOne = memo<{ isLoading: boolean; chart }>(function TabulationChartLevelOne({
  isLoading,
  chart,
}) {
  const dispatch = useResellersServiceNavigationDispatch();
  const { clickedOnTabulationChart } = useEventTracker();

  const handleOnClick = event => {
    const currentNavigation = event.data.name;

    clickedOnTabulationChart({ tabulationKind: toSnakeCase(currentNavigation) });

    unstable_batchedUpdates(() => {
      dispatch({ type: 'SET_CURRENT_PARENT_ID', payload: { parentIDs: { LVL1: event.data.code } } });
      dispatch({ type: 'SET_CURRENT_NAVIGATION', payload: { currentNavigation } });
      dispatch({ type: 'SET_CURRENT_PAGE', payload: { currentPage: 'LVL2' } });
    });
  };
  return (
    <Card css={{ gridArea: 'chart' }}>
      <CardTitle
        title="Revendedores por tipo de acionamento"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, com algum acionamento realizado pelo time da Força de Venda, agrupados por tipo de acionamento."
      />
      <ReactECharts
        loading={isLoading}
        option={{
          ...setOptions({ colors }),
          series: setChartsSeries({ columns: chart?.columns, columnsCustomOrder }),
          tooltip: setTooltip('Revendedores por tipo de acionamento'),
        }}
        style={{ minWidth: '100%', height: '320px' }}
        onClick={handleOnClick}
      />
      <ChartLegend columns={chart?.columns} colors={colors} />
    </Card>
  );
});
