import { Flex, Text, Tooltip } from '@grupoboticario/flora-react';
import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';

export function CardTitle({ title, tooltip }: { title: string; tooltip?: string }) {
  return (
    <Flex direction="row" align="center">
      <Text size="bodyLargeStandardMedium" color="$nonInteractiveAuxiliar">
        {title}
      </Text>
      {tooltip && (
        <Tooltip showArrow text={tooltip}>
          <QuestionMarkCircleIcon size="18" color="$nonInteractiveAuxiliar" css={{ margin: '1px 0 0 4px' }} />
        </Tooltip>
      )}
    </Flex>
  );
}
