import { IconCardIndicator, IndicatorCard } from '@/shared/components';
import { TabulationChartLevelOne } from '../components';
import { useGetDashboardData } from '@/shared/hooks';
import { decimal, percent, shouldRenderCard } from '@/shared/format';
import { ThreePeopleIcon } from '@grupoboticario/flora-react-icons';
import { Flex } from '@grupoboticario/flora-react';

export function TabulationLevelOne() {
  const { data, isLoading: isLoadingIndicators } = useGetDashboardData({ queryKey: 'calls', path: '/calls/dashboard' });

  return (
    <>
      <Flex gap="$4" css={{ margin: '$4 0' }} wrap="wrap">
        {shouldRenderCard(data?.totalBase.status) && (
          <IconCardIndicator
            title="Base ativa - FV"
            tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido nos 6 ciclos anteriores ao ciclo selecionado (de A0 a I6), excluindo Inícios e Reinícios."
            gridArea="total-base"
            icon={<ThreePeopleIcon />}
            data={data?.totalBase}
            format={decimal.format}
            isLoading={isLoadingIndicators}
          />
        )}
        {shouldRenderCard(data?.resellers.status) && (
          <IndicatorCard
            title="Revendedores acionados"
            tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, com algum acionamento realizado pelo time da Força de Venda."
            gridArea="triggered-resellers"
            value={data?.resellers?.realized ? decimal.format(data?.resellers?.realized) : '-'}
            goal={data?.resellers?.percentage ? `${percent.format(data?.resellers?.percentage / 100)} do total` : '-'}
            isLoading={isLoadingIndicators}
            isPartial={data?.resellers.status === 'partial'}
          />
        )}
        {shouldRenderCard(data?.totalCalls.status) && (
          <IndicatorCard
            title="Total de acionamentos"
            tooltip="Quantidade total de acionamentos realizados pelo time da Força de Vendas."
            gridArea="triggered-resellers-count"
            value={data?.totalCalls?.realized ? decimal.format(data?.totalCalls?.realized) : '-'}
            goal={data?.totalCalls?.average ? `Média de ${decimal.format(data?.totalCalls?.average)}` : '-'}
            isLoading={isLoadingIndicators}
            isPartial={data?.totalCalls.status === 'partial'}
          />
        )}
      </Flex>
      <TabulationChartLevelOne isLoading={isLoadingIndicators} chart={data?.graph} />
    </>
  );
}
