import { useFiltersDispatch } from '@/features';
import { useCyclesList, useFiltersList } from '@/shared/hooks';
import { TabList, Tab, TabPanel, Tabs, Text } from '@grupoboticario/flora-react';

export function TabNavigation({ consolidated, historical }) {
  const { isLoading: isLoadingCycles } = useCyclesList();
  const { isLoading: isLoadingFilters } = useFiltersList();
  const isLoading = isLoadingCycles || isLoadingFilters;

  const dispatch = useFiltersDispatch();

  function setTimeSeries(isTimeSeries) {
    dispatch({
      type: 'SET_TIME_SERIES',
      payload: isTimeSeries,
    });
  }

  return (
    <Tabs defaultValue="tab1" orientation="horizontal">
      <TabList aria-label="over-view-timeseries-tab" css={{ marginBottom: '12px' }}>
        <Tab onClick={() => setTimeSeries(false)} value="tab1">
          <Text>Consolidado</Text>
        </Tab>
        <Tab onClick={() => setTimeSeries(true)} disabled={isLoading} value="tab2">
          <Text>Visão Histórica</Text>
        </Tab>
      </TabList>
      <TabPanel css={{ padding: '0' }} value="tab1">
        {consolidated}
      </TabPanel>
      <TabPanel css={{ padding: '0' }} value="tab2">
        {historical}
      </TabPanel>
    </Tabs>
  );
}
