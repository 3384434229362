import { Text, styled } from '@grupoboticario/flora-react';

export const StyledPercentage = styled(Text, {
  display: 'block',
  fontSize: '$auxiliar',
  color: '$nonInteractiveAuxiliar',
  opacity: 0.7,
  marginTop: '$1',
  textAlign: 'right',
  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
    },
  },
});
