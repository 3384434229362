import { Flex } from '@grupoboticario/flora-react';
import { useEffect, useState } from 'react';

export const PieLegend = ({ columns, colors }) => {
  const [actualNavigation, setActualNavigation] = useState(1);
  const [navigationCount, setNavigationCount] = useState(1);
  const [actualIndex, setActualIndex] = useState(0);

  useEffect(() => {
    setActualNavigation(1);
    setNavigationCount(Math.ceil(columns?.length / 3));
    setActualIndex(0);
  }, [columns]);

  useEffect(() => {
    columns &&
      document.getElementById(columns[actualIndex]?.name)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [actualIndex, columns]);

  const handlePreviousItem = () => {
    if (actualNavigation === 1) return;
    setActualNavigation(actualNavigation - 1);

    setActualIndex(actualIndex - 3);
  };

  const handleNextItem = () => {
    if (actualNavigation === navigationCount) return;
    setActualNavigation(actualNavigation + 1);
    setActualIndex(actualIndex + 3);
  };

  return (
    <Flex
      align="center"
      direction="column"
      gap="$2"
      css={{
        borderTop: '1px solid $medium-light-2',
        fontSize: '0.7rem',
        width: '100%',
        paddingTop: '$5',
      }}
    >
      <Flex
        align="start"
        direction="column"
        gap="$2"
        css={{
          height: '80px',
          overflowY: 'scroll',
          scrollBehavior: 'smooth',
          scrollbarWidth: 'none',
          overflow: 'hidden',
        }}
      >
        {columns?.map((item, index) => {
          return (
            <Flex id={item.name} key={index} css={{ minHeight: '22px' }} gap="$2" direction="row" align="center">
              <div
                style={{
                  backgroundColor: colors[index],
                  minWidth: '0.7rem',
                  minHeight: '0.7rem',
                  borderRadius: '100%',
                }}
              />
              <strong>{item.percentage.toFixed(0)}%</strong> {item.name}
            </Flex>
          );
        })}
      </Flex>
      {columns?.length > 3 && (
        <Flex css={{ width: '100%', paddingTop: '$4' }} direction="row" align="center" justify="center">
          <div
            onClick={handlePreviousItem}
            style={{
              width: 0,
              height: 0,
              border: '5px solid transparent',
              borderTop: 0,
              borderBottom: `10px solid ${actualNavigation === 1 ? 'grey' : colors[0]}`,
              marginRight: '8px',
            }}
          />
          {actualNavigation} / {navigationCount}
          <div
            onClick={handleNextItem}
            style={{
              width: 0,
              height: 0,
              border: '5px solid transparent',
              borderTop: `10px solid ${actualNavigation === navigationCount ? 'grey' : colors[0]}`,
              borderBottom: 0,
              marginLeft: '8px',
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};
