import { Flex, Text } from '@grupoboticario/flora-react';
import { ChevronRightIcon, HouseIcon } from '@grupoboticario/flora-react-icons';
import {
  initialPage,
  useResellersServiceNavigation,
  useResellersServiceNavigationDispatch,
} from '../context/Navigation';
import { memo } from 'react';
import { useEventTracker } from '@/shared/hooks';
import { toSnakeCase } from '@/shared/format';

const Breadcrumb = memo(function Breadcrumb() {
  const { currentPage, navigation } = useResellersServiceNavigation();
  const dispatch = useResellersServiceNavigationDispatch();
  const [, firstLvl, ...rest] = navigation;
  const { clickedOnTabulationBreadcrumb } = useEventTracker();

  const setCurrentPageAsInitialPage = () => {
    if (currentPage === initialPage) return;

    clickedOnTabulationBreadcrumb({ tabulationKind: 'atendimento' });

    dispatch({ type: 'SET_CURRENT_NAVIGATION_HOME' });
    dispatch({ type: 'SET_CURRENT_PAGE', payload: { currentPage: initialPage } });
  };

  const breadcrumbItemEvent = item => {
    clickedOnTabulationBreadcrumb({ tabulationKind: toSnakeCase(item) });
  };

  return (
    <Flex direction="row" wrap="wrap" align="center" style={{ gridArea: 'breadcrumb', marginTop: '1rem' }}>
      <Flex align="center">
        <Flex onClick={setCurrentPageAsInitialPage} css={{ cursor: 'pointer', marginBlockStart: '-3px' }}>
          <HouseIcon css={{ color: '$actionableDefault' }} />
        </Flex>
        <ChevronRightIcon color="$nonInteractiveAuxiliar" />
      </Flex>
      <Flex align="center">
        <Text onClick={setCurrentPageAsInitialPage} css={{ color: '$actionableDefault', cursor: 'pointer' }}>
          {firstLvl}
        </Text>
        {navigation.length > 2 && <ChevronRightIcon color="$nonInteractiveAuxiliar" />}
      </Flex>
      {rest.map((item, index) => (
        <Flex key={index} align="center">
          <Text css={{ color: '$actionableDefault', cursor: 'pointer' }} onClick={() => breadcrumbItemEvent(item)}>
            {item}
          </Text>
          {index < rest.length - 1 && <ChevronRightIcon color="$nonInteractiveAuxiliar" />}
        </Flex>
      ))}
    </Flex>
  );
});

export { Breadcrumb };
