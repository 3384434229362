import { useMemo } from 'react';
import { ThreePeopleIcon } from '@grupoboticario/flora-react-icons';
import { Flex } from '@grupoboticario/flora-react';
import { Card, IconCardIndicator, DashboardGrid, IndicatorCard } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { decimal, percent, shouldRenderCard } from '@/shared/format';
import { useResellersServiceNavigation } from '../context';
import { TabulationChartLevelTwo, TabulationChartLevelThree } from '../components';
import { levelThreeGridSettings } from '../grid-settings';

export function TabulationLevelTwo() {
  const {
    parentIDs: { LVL1 },
    navigation,
  } = useResellersServiceNavigation();

  const levelTitle = navigation.at(2).toLowerCase();

  const hasThirdLevel = useMemo(() => {
    return navigation.at(2) === 'Contato efetivo';
  }, [navigation]);

  const { data, isLoading: isLoadingIndicators } = useGetDashboardData({
    queryKey: 'calls',
    path: '/calls/dashboard',
    parent: LVL1,
  });

  return (
    <>
      <Flex wrap="wrap" gap="$4" css={{ margin: '$4 0' }}>
        <Flex wrap="wrap" gap="$4" css={{ flexGrow: '1' }}>
          {shouldRenderCard(data?.totalBase.status) && (
            <IconCardIndicator
              title="Base ativa - FV"
              tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido nos 6 ciclos
        anteriores ao ciclo selecionado (de A0 a I6), excluindo Inícios e Reinícios."
              gridArea="total-base"
              icon={<ThreePeopleIcon />}
              data={data?.totalBase}
              format={decimal.format}
              isLoading={isLoadingIndicators}
            />
          )}
          {shouldRenderCard(data?.resellers.status) && (
            <IndicatorCard
              title={`Revendedores por tipo de acionamento - ${levelTitle}`}
              tooltip={`Quantidade de revendedores, gerenciados pela Força de Vendas, com algum acionamento realizado pelo time da Força de Venda, agrupados por tipo de acionamento.`}
              gridArea="one"
              value={data?.resellers?.realized ? decimal.format(data?.resellers?.realized) : '-'}
              goal={data?.resellers?.percentage ? `${percent.format(data?.resellers?.percentage / 100)} do total` : '-'}
              isLoading={isLoadingIndicators}
              isPartial={data?.resellers.status === 'partial'}
            />
          )}
        </Flex>
        <Flex wrap="wrap" gap="$4" css={{ flexGrow: '1' }}>
          {shouldRenderCard(data?.activeResellers.status) && (
            <IndicatorCard
              title={`Revendedores Ativos após um ${levelTitle}`}
              tooltip={`Quantidade de Revendedores Ativo - FV que tiveram pelo menos um acionamento do tipo ${navigation
                .at(2)
                .toLowerCase()}.`}
              gridArea="two"
              value={data?.activeResellers?.realized ? decimal.format(data?.activeResellers?.realized) : '-'}
              goal={
                data?.activeResellers?.percentage
                  ? `${percent.format(data?.activeResellers?.percentage / 100)} de taxa de atividade`
                  : '-'
              }
              isLoading={isLoadingIndicators}
              isPartial={data?.activeResellers.status === 'partial'}
            />
          )}
          {shouldRenderCard(data?.conversionRate.status) && (
            <IndicatorCard
              title={`Taxa de conversão do ${levelTitle}`}
              tooltip={`Percentual de revendedores ativos - FV  sobre o número de revendedores com ${levelTitle} tabulado.`}
              gridArea="three"
              value={data?.conversionRate?.rate ? percent.format(data?.conversionRate?.rate / 100) : '-'}
              goal={
                data?.conversionRate?.resellers
                  ? `${decimal.format(data?.conversionRate?.resellers)} contatos realizados`
                  : '-'
              }
              isLoading={isLoadingIndicators}
              isPartial={data?.conversionRate.status === 'partial'}
            />
          )}
        </Flex>
      </Flex>
      <Card css={{ gridArea: 'chart' }}>
        <DashboardGrid css={levelThreeGridSettings(hasThirdLevel)}>
          <TabulationChartLevelTwo
            tooltipText={levelTitle}
            isLoading={isLoadingIndicators}
            chart={data?.graph}
            hasChild={hasThirdLevel}
          />
          {hasThirdLevel && <TabulationChartLevelThree />}
        </DashboardGrid>
      </Card>
    </>
  );
}
