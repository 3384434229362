import { createContext, memo, useContext, useReducer } from 'react';

export const ResellersServiceNavigationContext = createContext(null);
export const ResellersServiceNavigationDispatchContext = createContext(null);

export const initialPage = 'LVL1';

const initialNavigation = ['Início', 'Atendimento'];

const initialState = {
  currentPage: initialPage,
  navigation: initialNavigation,
  parentIDs: {
    LVL1: null,
    LVL2: null,
    LVL3: null,
  },
  chartColor: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_PAGE': {
      return { ...state, currentPage: action.payload.currentPage };
    }
    case 'SET_CURRENT_PARENT_ID': {
      return { ...state, parentIDs: { ...state.parentIDs, ...action.payload.parentIDs } };
    }
    case 'SET_CHART_COLOR': {
      return { ...state, chartColor: action.payload.chartColor };
    }
    case 'SET_CURRENT_NAVIGATION': {
      if (action.payload.setAtLevel) {
        const newNavigation = state.navigation;
        newNavigation[action.payload.setAtLevel] = action.payload.currentNavigation;
        return { ...state, navigation: newNavigation };
      }

      const setNavigation = state.navigation.concat(action.payload.currentNavigation);
      return { ...state, navigation: setNavigation };
    }
    case 'SET_CURRENT_NAVIGATION_HOME': {
      return initialState;
    }
  }

  throw Error('Unknown action: ' + action.type);
}

export function useResellersServiceNavigation() {
  return useContext(ResellersServiceNavigationContext);
}

export function useResellersServiceNavigationDispatch() {
  return useContext(ResellersServiceNavigationDispatchContext);
}

export const ResellersServiceNavigationProvider = memo(function ResellersServiceNavigationProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ResellersServiceNavigationContext.Provider value={state}>
      <ResellersServiceNavigationDispatchContext.Provider value={dispatch}>
        {children}
      </ResellersServiceNavigationDispatchContext.Provider>
    </ResellersServiceNavigationContext.Provider>
  );
});
