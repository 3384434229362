import { Text, styled } from '@grupoboticario/flora-react';

export const InactivityLevelName = styled(Text, {
  position: 'relative',
  paddingLeft: '$4',
  fontSize: '$bodySmall',
  fontVariant: 'tabular-nums',

  '&::before': {
    position: 'absolute',
    backgroundColor: 'rgba(102, 102, 102, 1)',
    content: "''",
    width: '5px',
    height: '22px',
    borderRadius: '4px',
    display: 'inline-block',
    top: '-2px',
    left: 0,
  },
  variants: {
    levelColor: {
      A1: {
        '&::before': {
          backgroundColor: '#20AE43',
        },
      },
      A2: {
        '&::before': {
          backgroundColor: '#6CD115',
        },
      },
      A3: {
        '&::before': {
          backgroundColor: '#A7B820',
        },
      },
      I4: {
        '&::before': {
          backgroundColor: '#E29500',
        },
      },
      I5: {
        '&::before': {
          backgroundColor: '#FF5501',
        },
      },
      I6: {
        '&::before': {
          backgroundColor: '#FF0801',
        },
      },
      A0: {
        '&::before': {
          backgroundColor: '#49B1BF',
        },
      },
      I7: {
        '&::before': {
          backgroundColor: '#000000',
        },
      },
    },
  },
});
