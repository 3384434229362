import { useResellersServiceNavigation } from '../context/Navigation';
import { TabulationLevelOne, TabulationLevelTwo } from '../pages';

export function Routes() {
  const { currentPage } = useResellersServiceNavigation();

  if (currentPage === 'LVL1') {
    return <TabulationLevelOne />;
  }

  if (currentPage === 'LVL2') {
    return <TabulationLevelTwo />;
  }

  return <div>Error: Unknown page {currentPage}</div>;
}
