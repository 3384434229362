import { styled, CSS } from '@grupoboticario/flora-react';
import React from 'react';

const Table = styled('table', {
  width: '100%',
  marginTop: '$4',
});

interface StyledTableProps {
  sticky?: boolean;
  striped?: boolean;
  css?: CSS;
  children?: React.ReactNode;
}

const stickyStyles = {
  '& thead th:first-child': {
    position: 'sticky',
    left: 0,
    zIndex: 2,
  },
  '& tbody td:first-child': {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    backgroundColor: 'inherit',
  },
};

const stripedStyles = {
  '& thead th': {
    backgroundColor: '$backgroundSecondary',
  },
  '& tbody': {
    '& tr:nth-child(even)': {
      backgroundColor: '$backgroundSecondary',
    },
    '& tr:nth-child(odd)': {
      backgroundColor: 'white',
    },
  },
};

export const StyledTable = ({ sticky = false, striped = false, css, children }: StyledTableProps) => {
  return (
    <Table
      css={{
        ...(sticky ? stickyStyles : {}),
        ...(striped ? stripedStyles : {}),
        ...css,
      }}
    >
      {children}
    </Table>
  );
};
