import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
    "total-base total-base"
    "available available"
    "blocked blocked"
    "perishable perishable"
    "defaulter defaulter"
    "active-resellers-chart active-resellers-chart"
    "inactivity inactivity"
    "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
     "total-base total-base"
     "available blocked"
     "perishable defaulter"
     "active-resellers-chart active-resellers-chart"
     "inactivity inactivity"
     "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
      "total-base total-base active-resellers-chart active-resellers-chart"
      "available blocked active-resellers-chart active-resellers-chart"
      "perishable defaulter active-resellers-chart active-resellers-chart"
      "inactivity inactivity my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
};
