import { ReactEChartsProps } from '@/shared/components';
import { sortColumnsByAscendingName, sortColumnsByCustomOrder } from './columns';
import { decimal } from '../number';

export function setChartsSeries({ columns, columnsCustomOrder }): ReactEChartsProps['option']['series'] {
  const sortColumns = columnsCustomOrder
    ? sortColumnsByCustomOrder({ columns, customOrderArray: columnsCustomOrder })
    : sortColumnsByAscendingName({ columns });
  return sortColumns?.map(column => {
    return {
      type: 'bar',
      seriesLayoutBy: 'row',
      name: column.name,
      data: [
        {
          code: column.code,
          resellers: column.resellers,
          value: (column.percentage ?? 0).toFixed(0),
          name: column.name,
        },
      ],
      label: {
        show: true,
        position: 'inside',
        formatter: value => `${column.resellers ? decimal.format(value.data.resellers) : ''}\n${value.value}%`,
        color: '#fff',
        textBorderColor: 'inherit',
        textBorderWidth: 3,
      },
    };
  });
}
