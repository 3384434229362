import { Flex, Text } from '@grupoboticario/flora-react';

export const ChartLegend = ({ columns, colors }) => {
  return (
    <Flex align="center" justify="center" wrap="wrap" gap="$2" css={{ marginTop: '$4' }}>
      {columns?.map((item, index) => {
        return (
          <Flex gap="$1" key={item.code} direction="row" align="start">
            <div
              style={{
                backgroundColor: colors[index],
                minWidth: '0.7rem',
                minHeight: '0.7rem',
                borderRadius: '0.125rem',
              }}
            />
            <Text css={{ fontSize: '0.7rem' }}>{item.name}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
