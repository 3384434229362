import { getImageMeuClubeGb } from '@/shared/assets';
import { Hidden, Flex, Image, Text } from '@grupoboticario/flora-react';
import { StyledPercentage } from '../Table';
import { meuClubeGbLevelNameMap } from '@/shared/format';

export function GbClubLevelName({ props }) {
  return (
    <Flex align="center" wrap="nowrap">
      <Hidden only={['mobile']}>
        <Image
          css={{
            marginInlineEnd: '$3',
            width: '40px',
            height: '40px',
            borderRadius: '100%',
            backgroundColor: 'rgba(188, 188, 188, 0.2)',
          }}
          src={getImageMeuClubeGb(props.getValue())}
          alt={`Meu Clube GB ${props.getValue()}`}
          loading="lazy"
        />
      </Hidden>
      <Flex direction="column">
        <Text css={{ textAlign: 'left' }} size="bodyMediumStandard">
          {props.getValue() === 'indefinido' || props.getValue() === 'novoRevendedor'
            ? 'Sem Classificação'
            : meuClubeGbLevelNameMap[props.getValue()]}
        </Text>
        {(props.getValue() === 'indefinido' || props.getValue() === 'novoRevendedor') && (
          <StyledPercentage align="left">{meuClubeGbLevelNameMap[props.getValue()]}</StyledPercentage>
        )}
      </Flex>
    </Flex>
  );
}
