import { useState } from 'react';
import { Flex } from '@grupoboticario/flora-react';
import { decimal, percent } from '@/shared/format';
import {
  Card,
  StyledPercentage,
  StyledTable,
  StyledTr,
  StyledTh,
  StyledTd,
  StyledInactivityLevelName,
  TableSkeleton,
  CardTitle,
  ToggleButton,
} from '@/shared/components';
import { useEventTracker } from '@/shared/hooks';

export function InactivityLevelCard({ isLoading, basePerInactivityLevel, css = {} }) {
  const [representationBy, setRepresentationBy] = useState<'captation-channel' | 'brand'>('captation-channel');
  const filteredBasePerInactivityLevel = basePerInactivityLevel?.filter(
    item => item.code !== 'A0' && item.code !== 'I7+',
  );
  const beginnings = basePerInactivityLevel?.filter(item => item.code === 'A0')[0];
  const reboots = basePerInactivityLevel?.filter(item => item.code === 'I7+')[0];

  const { clickedOnRepresentationTab } = useEventTracker();

  const isCaptationChannel = representationBy === 'captation-channel';

  const emptyTr = (numberOfItems: number = isCaptationChannel ? 3 : 4): number[] =>
    Array.from({ length: numberOfItems }, (_, i) => i);

  return (
    <Card css={{ ...css, gridArea: 'inactivity-level' }}>
      <Flex
        css={{
          flexDirection: 'column',
          '@tablet': { flexDirection: 'row' },
        }}
        justify="space-between"
      >
        <CardTitle
          title="Nível de inatividade - FV"
          tooltip="Quantidade de revendedores da base ativa e revendedores ativos, gerenciados pela Força de Vendas, separados pelas marcas O Boticário, Eudora e Multimarcas. Detalhamento por atividade em cada um das marcas ou por meio de captação, de acordo com o nível de inatividade no ciclo selecionado."
        />
        <ToggleButton
          leftText="Meio de captação"
          onLeftButtonClick={() => {
            setRepresentationBy('captation-channel'), clickedOnRepresentationTab({ tabName: 'meios_de_captacao' });
          }}
          rightText="Marcas"
          onRightButtonClick={() => {
            setRepresentationBy('brand'), clickedOnRepresentationTab({ tabName: 'marcas' });
          }}
        />
      </Flex>
      <div style={{ overflowX: 'scroll', overflowY: 'hidden', width: '100%', textAlign: 'right' }}>
        <Flex direction="row" justify="space-between" css={{ minWidth: '100%', position: 'relative' }}>
          <StyledTable
            sticky
            striped
            css={{ minWidth: 726, zIndex: 0, '& td:nth-child(6), th:nth-child(6)': { borderRight: '1px solid #ccc' } }}
          >
            <thead>
              <StyledTr css={{ textAlign: 'center', border: 0 }}>
                <StyledTh />
                <StyledTh />
                <StyledTh
                  colSpan={4}
                  css={{
                    minWidth: 390,
                    borderBottom: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                  }}
                >
                  Atividade
                </StyledTh>
                {isCaptationChannel ? (
                  <StyledTh colSpan={5} css={{ minWidth: 390, paddingInline: '$4', borderBottom: '1px solid #ccc' }}>
                    Representatividade das ativas por meio de captação
                  </StyledTh>
                ) : (
                  <StyledTh colSpan={6} css={{ minWidth: 390, paddingInline: '$4', borderBottom: '1px solid #ccc' }}>
                    Representatividade das ativas por marca
                  </StyledTh>
                )}
              </StyledTr>
              <StyledTr>
                <StyledTh css={{ minWidth: '200px', '@tablet': { minWidth: '240px' }, textAlign: 'left' }}>
                  Classificação
                </StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Base</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Eudora</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>O Boticário</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Multimarca</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Geral</StyledTh>
                {isCaptationChannel ? (
                  <>
                    <StyledTh>App RE</StyledTh>
                    <StyledTh>ER</StyledTh>
                    <StyledTh>Super FV</StyledTh>
                  </>
                ) : (
                  <>
                    <StyledTh>BOTI</StyledTh>
                    <StyledTh>O.U.I.</StyledTh>
                    <StyledTh>QDB</StyledTh>
                    <StyledTh>EUD</StyledTh>
                  </>
                )}
              </StyledTr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableSkeleton columns={isCaptationChannel ? 9 : 10} rows={8} />
              ) : (
                <>
                  {filteredBasePerInactivityLevel?.map(item => {
                    return (
                      <StyledTr key={item.code}>
                        <StyledTd css={{ width: '100px', textAlign: 'left' }}>
                          <StyledInactivityLevelName levelColor={item.code}>{item.code}</StyledInactivityLevelName>
                        </StyledTd>
                        <StyledTd>
                          {item?.base ? decimal.format(item.base) : '—'}
                          <StyledPercentage>
                            {item?.basePercentage ? `${percent.format(item.basePercentage / 100)} do total` : '—'}
                          </StyledPercentage>
                        </StyledTd>
                        {item?.activity
                          ? item.activity.map(kind => {
                              return (
                                <StyledTd key={kind.code}>
                                  {decimal.format(kind.value)}
                                  <StyledPercentage>{percent.format(kind.percentage / 100)}</StyledPercentage>
                                </StyledTd>
                              );
                            })
                          : emptyTr().map((_, index) => <StyledTd key={index}>—</StyledTd>)}
                        {isCaptationChannel ? (
                          <>
                            {item?.channel
                              ? item?.channel?.map(channel => {
                                  if (channel.code === 'appSuper' || channel.code === 'portal') return null;
                                  return (
                                    <StyledTd key={`${item.code}-${channel.code}`}>
                                      {percent.format(channel.value / 100)}
                                    </StyledTd>
                                  );
                                })
                              : emptyTr().map((_, index) => <StyledTd key={index}>—</StyledTd>)}
                          </>
                        ) : (
                          <>
                            {item?.brand
                              ? item.brand.map(brand => {
                                  if (brand.code === 'multiB') return null;

                                  return (
                                    <StyledTd key={`${item.code}-${brand.code}`}>
                                      {percent.format(brand.value / 100)}
                                    </StyledTd>
                                  );
                                })
                              : emptyTr().map((_, index) => <StyledTd key={index}>—</StyledTd>)}
                          </>
                        )}
                      </StyledTr>
                    );
                  })}
                  {beginnings?.code && (
                    <StyledTr>
                      <StyledTd css={{ textAlign: 'left' }}>
                        <StyledInactivityLevelName levelColor={beginnings.code}>Inícios</StyledInactivityLevelName>
                      </StyledTd>
                      {beginnings?.activity ? (
                        <>
                          <StyledTd>—</StyledTd>
                          {beginnings?.activity?.map(kind => {
                            return (
                              <StyledTd key={kind.code}>
                                {decimal.format(kind.value)}
                                <StyledPercentage>{percent.format(kind.percentage / 100)}</StyledPercentage>
                              </StyledTd>
                            );
                          })}
                        </>
                      ) : (
                        emptyTr().map((_, index) => <StyledTd key={index}>—</StyledTd>)
                      )}
                      {beginnings?.base ? (
                        <>
                          {isCaptationChannel ? (
                            <>
                              {beginnings?.channel?.map(channel => {
                                if (channel.code === 'appSuper' || channel.code === 'portal') return null;

                                return (
                                  <StyledTd key={`${beginnings.code}-${channel.code}`}>
                                    {percent.format(channel.value / 100)}
                                  </StyledTd>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {beginnings?.brand?.map(brand => {
                                if (brand.code === 'multiB') return null;
                                return (
                                  <StyledTd key={`${beginnings.code}-${brand.code}`}>
                                    {percent.format(brand.value / 100)}
                                  </StyledTd>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {emptyTr().map((_, index) => (
                            <StyledTd key={index}>—</StyledTd>
                          ))}
                        </>
                      )}
                    </StyledTr>
                  )}
                  {reboots?.code && (
                    <StyledTr>
                      <StyledTd css={{ textAlign: 'left' }}>
                        <StyledInactivityLevelName levelColor={reboots.code.replace('+', '')}>
                          Reinícios
                        </StyledInactivityLevelName>
                      </StyledTd>
                      {reboots.activity ? (
                        <>
                          <StyledTd>—</StyledTd>
                          {reboots.activity.map(kind => {
                            return (
                              <StyledTd key={kind.code}>
                                {decimal.format(kind.value)}
                                <StyledPercentage>{percent.format(kind.percentage / 100)}</StyledPercentage>
                              </StyledTd>
                            );
                          })}
                        </>
                      ) : (
                        emptyTr().map((_, index) => <StyledTd key={index}>—</StyledTd>)
                      )}
                      {reboots?.base ? (
                        <>
                          {isCaptationChannel ? (
                            <>
                              {reboots?.channel?.map(channel => {
                                if (channel.code === 'appSuper' || channel.code === 'portal') return null;

                                return (
                                  <StyledTd key={`${reboots.code}-${channel.code}`}>
                                    {percent.format(channel.value / 100)}
                                  </StyledTd>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {reboots?.brand?.map(brand => {
                                if (brand.code === 'multiB') return null;
                                return (
                                  <StyledTd key={`${reboots.code}-${brand.code}`}>
                                    {percent.format(brand.value / 100)}
                                  </StyledTd>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {emptyTr().map((_, index) => (
                            <StyledTd key={index}>—</StyledTd>
                          ))}
                        </>
                      )}
                    </StyledTr>
                  )}
                </>
              )}
            </tbody>
          </StyledTable>
        </Flex>
      </div>
    </Card>
  );
}
