import { SkeletonItem } from '../SkeletonLoading';
import { StyledTd } from './StyledTd';
import { StyledTr } from './StyledTr';

export function TableSkeleton({ columns = 3, rows = 3 }: { columns?: number; rows?: number }) {
  const tableColumns = Array.from({ length: columns }, (_, index) => index);
  const tableRows = Array.from({ length: rows }, (_, index) => index);

  return (
    <>
      {tableRows.map(row => (
        <StyledTr key={`row-${row}`}>
          {tableColumns.map(column => (
            <StyledTd key={`column-${column}`}>
              <SkeletonItem>&nbsp;</SkeletonItem>
            </StyledTd>
          ))}
        </StyledTr>
      ))}
    </>
  );
}
