import { Text } from '@grupoboticario/flora-react';
import { useResellersServiceNavigation } from '../context/Navigation';
import { memo, useMemo } from 'react';

export const ChartTitle = memo(function ChartTitle() {
  const { navigation } = useResellersServiceNavigation();
  const memoizedChartTitle = useMemo(() => {
    return navigation.at(2);
  }, [navigation]);

  return (
    <Text color="$nonInteractiveAuxiliar">
      Revendedores por tipo de acionamento - <span style={{ textTransform: 'lowercase' }}>{memoizedChartTitle}</span>
    </Text>
  );
});
