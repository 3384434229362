import { StyledPercentage } from '@/shared/components';
import { percentNoFraction, meuClubeGbLevelNameMap, currencyNoFraction } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';

export function TableCellText({
  displayValue,
  percentage,
  textPercentage,
  isCurrency = true,
  isMyGbClubTable = false,
}) {
  const finalTextPercentage = isMyGbClubTable ? meuClubeGbLevelNameMap[textPercentage] : textPercentage;

  return (
    <Flex direction="column">
      {displayValue === undefined ? '-' : isCurrency ? currencyNoFraction.format(displayValue) : displayValue}
      <StyledPercentage>
        {percentage === undefined ? '-' : `${percentNoFraction.format(percentage / 100)} dos ${finalTextPercentage}`}
      </StyledPercentage>
    </Flex>
  );
}
