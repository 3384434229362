import { globalCss, keyframes } from '@grupoboticario/flora-react';

const animationShimmer = keyframes({
  '0%': {
    backgroundPosition: '-200px 0',
  },
  '100%': {
    backgroundPosition: 'calc(200px + 100%) 0',
  },
});

export const styles = globalCss({
  body: {
    overflow: 'initial !important',
  },
  '[id*="gr-dashboards"]': {
    fontFamily:
      'IBM Plex Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  },
  'svg, img': {
    verticalAlign: 'initial',
  },
  '.loading-placeholder': {
    display: 'inline-block',
    color: 'rgba(0,0,0,0) !important',
    animation: `${animationShimmer} 1s ease-in-out infinite`,
    backgroundColor: '#eee',
    backgroundImage: 'linear-gradient(90deg, #eee, #fff, #eee)',
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    minWidth: '60px',
  },
  '.loading-placeholder-subtitle': {
    marginTop: '4px',
    maxHeight: '20px',
    width: '120px',
  },
  '.ant-select-arrow': {
    color: '$actionableDefault !important',
  },
  '.ant-select-focused .ant-select-selector': {
    boxShadow: 'inset 0 0 0 1px $actionableDefault !important, 0 4px 28px -8px rba(0,0,0,0.4) !important',
  },
  '.ant-select-selector, .ant-select-single, .ant-select-item-option-content': {
    fontFamily:
      'IBM Plex Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  },
  '.ant-select-selector': {
    height: '48px !important',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    '&:focus, &:hover, &:active, &:focus-within': {
      border: '1px solid  $actionableDefault!important',
    },
  },
  '.ant-select-single': {
    display: 'flex',
    alignItems: 'center',
    height: '48px !important',
  },
  '.ant-select-selection-search-input, .ant-select-single.ant-select-lg': {
    height: '48px !important',
  },
  '.ant-select-outlined.ant-select-multiple .ant-select-selection-item': {
    backgroundColor: 'rgba(245, 241, 235, 1)',
    border: '1px solid rgba(0, 0, 0, 0.15)',
  },
  '.ant-select-multiple .ant-select-selector': {
    padding: '0 4px',
  },
  '.ant-select-selector > .ant-select-selection-item': {
    lineHeight: '48px !important',
  },
  '.ant-select-selection-placeholder': {
    lineHeight: '48px !important',
  },
  '.ant-select-single .ant-select-selection-placeholder': {
    color: '$nonInteractivePredominant',
  },
  '.ant-select-multiple .ant-select-selection-placeholder': {
    color: '$nonInteractivePredominant',
  },
  '.ant-select-item-option-content': {
    fontVariantNumeric: 'tabular-nums slashed-zero',
    display: 'inline-flex',
    alignItems: 'center',
  },
  '.ant-select-item-option-content [role="checkbox"]': {
    marginRight: '$2',
  },
  '.ant-select-item-option-state': {
    display: 'none !important',
  },
  '.ant-select-selection-overflow': {
    flexWrap: 'nowrap !important',
    maxWidth: '80% !important',
  },
  '.ant-select-selection-overflow-item': {
    display: 'inline-block !important',
    maxWidth: '80% !important',
  },
  '.ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item-option-active': {
    backgroundColor: '#F5F5F5 !important',
  },
  '.ant-drawer .ant-drawer-body': {
    padding: '0',
  },
});
