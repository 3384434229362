import { clsx } from 'clsx';
import { Flex, Text } from '@grupoboticario/flora-react';
import { Card, CardTitle } from '@/shared/components';

interface IndicatorCardProps {
  title: string;
  tooltip: string;
  gridArea: string;
  value: string;
  goal: string;
  isLoading: boolean;
  isPartial?: boolean;
}

export function IndicatorCard({ title, tooltip, gridArea, value, goal, isLoading, isPartial }: IndicatorCardProps) {
  return (
    <Card css={{ gridArea, flexGrow: '1', maxWidth: '100%' }}>
      <Flex direction="column" justify="center" css={{ height: '100%', flex: '1 1 auto' }}>
        <CardTitle title={title} tooltip={tooltip} />
        <Text
          size="subTitleDeskBold"
          weight="heavy"
          className={clsx(isLoading && 'loading-placeholder')}
          css={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Text>
        {!isPartial && (
          <Text
            className={clsx(isLoading && 'loading-placeholder loading-placeholder-subtitle')}
            color="$nonInteractiveAuxiliar"
            size="bodyLargeStandardMedium"
            css={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {goal}
          </Text>
        )}
      </Flex>
    </Card>
  );
}
