import { useQuery } from '@tanstack/react-query';
import { fetchFilters } from '@/shared/api';
import { useEffect } from 'react';
import { useFilters, useFiltersDispatch } from '@/features';

export const useFiltersList = () => {
  const {
    selectedCycles,
    isFirstRequest,
    requestData: { cycle },
  } = useFilters();

  const dispatch = useFiltersDispatch();

  const { data, isLoading } = useQuery({
    staleTime: 1000 * 60 * 60,
    enabled: selectedCycles?.length > 0,
    queryKey: ['filters', selectedCycles],
    queryFn: ({ signal }) => fetchFilters({ cycle: selectedCycles, signal }),
  });

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'SET_FILTERS',
        payload: { structures: data?.structures, model: data?.model },
      });
      if (isFirstRequest) {
        dispatch({
          type: 'SET_REQUEST_DATA',
        });
        dispatch({
          type: 'SET_IS_FIRST_REQUEST',
          payload: false,
        });
      }
    }
  }, [data, cycle, dispatch]);

  return { data, isLoading };
};
