import { Select } from 'antd';
import { Checkbox, Flex, Text } from '@grupoboticario/flora-react';
import { useEventTracker } from '@/shared/hooks';
import { StyledSelectAllItems } from './SelectAllItems';
import type { FilterSelectProps } from './types';
import { StructureFilterOption, useFilters, useFiltersDispatch } from '@/features';
import { DisabledInput } from './DisabledInput';

const mapFilterNameToEventName: Record<
  'region' | 'uf' | 'cp' | 'cs' | 'sector',
  'região' | 'uf' | 'franqueado' | 'espaco_revendedor' | 'setor'
> = {
  region: 'região',
  uf: 'uf',
  cp: 'franqueado',
  cs: 'espaco_revendedor',
  sector: 'setor',
};

const { Option } = Select;

export function FilterSelect({ id: structureType, labelText, isLoading, toggleAll }: FilterSelectProps) {
  const dispatch = useFiltersDispatch();
  const { structures, salesForce } = useFilters();
  const { clickedOnFilter } = useEventTracker();

  const salesForceModel = salesForce.find(item => item.selected)?.model;

  const parentsOptions = new Map<string, StructureFilterOption>();

  const options = structures[salesForceModel]?.map(({ parents, code, name, selected, enabled }) => {
    if (structureType === 'sector') {
      return { code, name, selected, enabled };
    }

    parents.forEach(parent => {
      if (!parentsOptions.has(parent.code)) {
        parentsOptions.set(parent.code, parent);
      }
    });
  });

  const filterOptions =
    structureType !== 'sector'
      ? Array.from(parentsOptions.values()).filter(parent => parent.type === structureType)
      : options;

  const selectedOptions = filterOptions?.filter(item => item?.selected);
  const selectedOptionsCodes = selectedOptions?.map(item => item.code);
  const everyEnabledOptionSelected = filterOptions?.filter(item => item?.enabled).every(item => item.selected);

  const handleSelect = (structureType: string) => (selectedCodes: number[]) => {
    dispatch({
      type: 'SELECT_FILTER',
      payload: { structureType, selectedCodes },
    });
  };

  function handleSelectAll(event: React.MouseEvent<HTMLAnchorElement>, filterKind: string) {
    event.preventDefault();

    dispatch({
      type: `SELECT_ALL_OPTIONS`,
      payload: filterKind,
    });
  }

  const showToggleAllAction = toggleAll && filterOptions?.length > 1;
  const hasOnlyOneOption = filterOptions?.length === 1;
  const isRegionOrUf = structureType === 'region' || structureType === 'uf';

  return (
    <Flex direction="column" wrap="wrap" css={{ width: '100%' }}>
      <Text
        as="label"
        size="exceptionsRestrictedRegular"
        css={{
          display: 'flex',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
        }}
        htmlFor={structureType}
        title={labelText}
      >
        {labelText}
        {showToggleAllAction && (
          <StyledSelectAllItems css={{ paddingLeft: 'auto' }} href="#" onClick={e => handleSelectAll(e, structureType)}>
            {`${everyEnabledOptionSelected ? 'Desmarcar' : 'Marcar'} todos`}
          </StyledSelectAllItems>
        )}
      </Text>
      {hasOnlyOneOption ? (
        filterOptions?.map(item => <DisabledInput key={item.code} text={item.name} />)
      ) : (
        <Select
          disabled={isLoading || hasOnlyOneOption}
          loading={isLoading}
          showSearch
          mode={hasOnlyOneOption ? undefined : 'multiple'}
          allowClear
          style={{ width: '100%' }}
          placeholder="Pesquise ou selecione"
          placement="bottomLeft"
          id={structureType}
          onChange={handleSelect(structureType)}
          filterOption={(input, option) => (`${option?.label}` ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) => {
            if (optionA.children[1].props['data-selected'] === optionB.children[1].props['data-selected']) {
              return optionA.value < optionB.value ? -1 : 1;
            } else {
              return optionA.children[1].props['data-selected'] > optionB.children[1].props['data-selected'] ? -1 : 1;
            }
          }}
          maxTagCount={isRegionOrUf ? 'responsive' : 1}
          value={selectedOptionsCodes}
          optionLabelProp="label"
          onFocus={() => clickedOnFilter({ filterName: mapFilterNameToEventName[structureType] })}
        >
          {filterOptions?.map(item => {
            if (!item?.enabled) return;
            return (
              <Option data-testid="filter-option" title={item.name} key={item.name} value={item.code} label={item.name}>
                <Checkbox checked={item.selected} id={`${structureType}-${item.code}`} />
                <span data-selected={item.selected}>{item.name}</span>
              </Option>
            );
          })}
        </Select>
      )}
    </Flex>
  );
}
