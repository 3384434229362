import { useState } from 'react';
import { Image, Text, Tooltip, Flex, CSS, Hidden } from '@grupoboticario/flora-react';
import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';
import { Card, CardTitle, ToggleButton } from '@/shared/components';
import { decimal, percent } from '@/shared/format';
import { StyledPercentage, StyledTable, StyledTd, StyledTh, StyledTr, TableSkeleton } from '@/shared/components/Table';
import { getImageMeuClubeGb } from '@/shared/assets';
import { useEventTracker } from '@/shared/hooks';

type meuClubeGbCard = {
  isLoading: boolean;
  basePerMeuClubeGBLevel: meuClubeGbLevel[];
  css?: CSS;
};

type meuClubeGbLevel = {
  code: keyof typeof meuClubeGbLevelNameMap;
  base: number;
  basePercentage: number;
  activity: {
    code: 'eudora' | 'oBoticario' | 'multimarca' | 'total';
    value: number;
    percentage: number;
  }[];
  activePercentage: number;
  brand: {
    code: 'boti' | 'oui' | 'qdb' | 'eud' | 'multiB';
    value: number;
  }[];
  channel: {
    code: 'appRe' | 'er' | 'appSuper' | 'portal' | 'superFv';
    value: number;
  }[];
};

const meuClubeGbLevelNameMap = {
  diamante: 'Diamantes',
  esmeralda: 'Esmeraldas',
  rubi: 'Rubis',
  platina: 'Platinas',
  ouro: 'Ouros',
  prata: 'Pratas',
  bronze: 'Bronzes',
  novoRevendedor: 'Novos Revendedores',
  indefinido: 'Em atualização',
};

export function MeuClubeGbCard({ isLoading, basePerMeuClubeGBLevel, css }: meuClubeGbCard) {
  const [representationBy, setRepresentationBy] = useState<'captation-channel' | 'brand'>('captation-channel');

  const { clickedOnRepresentationTab } = useEventTracker();

  const isCaptationChannel = representationBy === 'captation-channel';

  const emptyTr = (numberOfItems: number): number[] => Array.from({ length: numberOfItems }, (_, i) => i);

  return (
    <Card css={{ ...css, gridArea: 'meu-clube-gb' }}>
      <Flex
        css={{
          flexDirection: 'column',
          '@tablet': { flexDirection: 'row' },
        }}
        justify="space-between"
      >
        <CardTitle
          title="Meu clube GB - FV"
          tooltip="Quantidade de revendedores da base ativa e revendedores ativos, gerenciados pela Força de Vendas, separados pelas marcas O Boticário, Eudora e Multimarcas. Detalhamento por atividade em cada um das marcas ou por meio de captação, de acordo com a faixa no Meu Clube GB no ciclo selecionado."
        />
        <ToggleButton
          leftText="Meio de captação"
          onLeftButtonClick={() => {
            setRepresentationBy('captation-channel'), clickedOnRepresentationTab({ tabName: 'meios_de_captacao' });
          }}
          rightText="Marcas"
          onRightButtonClick={() => {
            setRepresentationBy('brand'), clickedOnRepresentationTab({ tabName: 'marcas' });
          }}
        />
      </Flex>
      <div style={{ overflowX: 'scroll', overflowY: 'hidden', width: '100%', textAlign: 'right' }}>
        <Flex direction="row" justify="space-between" css={{ minWidth: '100%', position: 'relative' }}>
          <StyledTable
            sticky
            striped
            css={{ minWidth: 726, zIndex: 0, '& td:nth-child(6), th:nth-child(6)': { borderRight: '1px solid #ccc' } }}
          >
            <thead>
              <StyledTr css={{ border: 0, textAlign: 'center', minWidth: 1120 }}>
                <StyledTh />
                <StyledTh />
                <StyledTh
                  colSpan={4}
                  css={{ minWidth: 390, borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}
                >
                  Atividade
                </StyledTh>
                {isCaptationChannel ? (
                  <StyledTh
                    colSpan={5}
                    css={{ minWidth: 390, textAlign: 'center', paddingInline: '$4', borderBottom: '1px solid #ccc' }}
                  >
                    Representatividade das ativas por meio de captação
                  </StyledTh>
                ) : (
                  <StyledTh
                    colSpan={6}
                    css={{ minWidth: 390, textAlign: 'center', paddingInline: '$4', borderBottom: '1px solid #ccc' }}
                  >
                    Representatividade das ativas por marca
                  </StyledTh>
                )}
              </StyledTr>
              <StyledTr>
                <StyledTh css={{ minWidth: '200px', '@tablet': { minWidth: '240px' }, textAlign: 'left' }}>
                  Classificação
                </StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Base</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Eudora</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>O Boticário</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Multimarca</StyledTh>
                <StyledTh css={{ minWidth: '100px' }}>Geral</StyledTh>
                {isCaptationChannel ? (
                  <>
                    <StyledTh>App RE</StyledTh>
                    <StyledTh>ER</StyledTh>
                    <StyledTh>Super FV</StyledTh>
                  </>
                ) : (
                  <>
                    <StyledTh>BOTI</StyledTh>
                    <StyledTh>O.U.I.</StyledTh>
                    <StyledTh>QDB</StyledTh>
                    <StyledTh>EUD</StyledTh>
                  </>
                )}
              </StyledTr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableSkeleton columns={isCaptationChannel ? 9 : 10} rows={8} />
              ) : (
                basePerMeuClubeGBLevel?.map(item => {
                  return (
                    <StyledTr key={item.code}>
                      <StyledTd css={{ width: '100px', textAlign: 'right' }}>
                        <Flex align="center">
                          <Hidden only={['mobile']}>
                            <Image
                              css={{
                                marginInlineEnd: '$3',
                                width: '40px',
                                height: '40px',
                                borderRadius: '100%',
                                backgroundColor: 'rgba(188, 188, 188, 0.2)',
                              }}
                              src={getImageMeuClubeGb(item.code)}
                              alt={`Meu Clube GB ${item.code}`}
                              loading="lazy"
                            />
                          </Hidden>

                          <div style={{ textAlign: 'left' }}>
                            {item.code !== 'novoRevendedor' && item.code !== 'indefinido' ? (
                              <Text size="bodyMediumStandard">{meuClubeGbLevelNameMap[item.code]}</Text>
                            ) : null}

                            {item.code === 'novoRevendedor' && (
                              <>
                                Sem classificação
                                <Tooltip
                                  showArrow
                                  text="Esta classificação refere-se a novas REs que estão aguardando a sua classificação em alguma faixa do meu Clube GB."
                                >
                                  <QuestionMarkCircleIcon
                                    size="14"
                                    color="$nonInteractiveAuxiliar"
                                    css={{ verticalAlign: '-2px', marginLeft: '$1' }}
                                  />
                                </Tooltip>
                                <StyledPercentage align="left">Novos revendedores</StyledPercentage>
                              </>
                            )}

                            {item.code === 'indefinido' && (
                              <>
                                Sem classificação
                                <Tooltip
                                  showArrow
                                  text="Esta classificação refere-se a REs provenientes do Meu Club Boti que se estão aguardando a sua classificação no Meu Clube GB"
                                >
                                  <QuestionMarkCircleIcon
                                    size="14"
                                    color="$nonInteractiveAuxiliar"
                                    css={{ verticalAlign: '-2px', marginLeft: '$1' }}
                                  />
                                </Tooltip>
                                <StyledPercentage align="left">Em atualização</StyledPercentage>
                              </>
                            )}
                          </div>
                        </Flex>
                      </StyledTd>
                      <StyledTd>
                        {item?.base ? decimal.format(item.base) : '—'}
                        <StyledPercentage>
                          {item?.basePercentage ? `${percent.format(item.basePercentage / 100)} do total` : '—'}
                        </StyledPercentage>
                      </StyledTd>
                      {item?.activity
                        ? item.activity.map(kind => {
                            return (
                              <StyledTd key={kind.code}>
                                {decimal.format(kind.value)}
                                <StyledPercentage>{percent.format(kind.percentage / 100)}</StyledPercentage>
                              </StyledTd>
                            );
                          })
                        : emptyTr(5).map((_, index) => <StyledTd key={index}>—</StyledTd>)}

                      {isCaptationChannel ? (
                        <>
                          {item?.channel
                            ? item?.channel?.map(channel => {
                                if (channel.code === 'appSuper' || channel.code === 'portal') return null;
                                return (
                                  <StyledTd key={`${item.code}-${channel.code}`}>
                                    {percent.format(channel.value / 100)}
                                  </StyledTd>
                                );
                              })
                            : emptyTr(2).map((_, index) => <StyledTd key={index}>—</StyledTd>)}
                        </>
                      ) : (
                        <>
                          {item?.brand
                            ? item.brand.map(brand => {
                                if (brand.code === 'multiB') return null;

                                return (
                                  <StyledTd key={`${item.code}-${brand.code}`}>
                                    {percent.format(brand.value / 100)}
                                  </StyledTd>
                                );
                              })
                            : emptyTr(3).map((_, index) => <StyledTd key={index}>—</StyledTd>)}
                        </>
                      )}
                    </StyledTr>
                  );
                })
              )}
            </tbody>
          </StyledTable>
        </Flex>
      </div>
    </Card>
  );
}
