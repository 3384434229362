import { Flex, Image, Text, Tooltip, CSS, Hidden } from '@grupoboticario/flora-react';
import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';
import { decimal, meuClubeGbLevelNameMap, percent } from '@/shared/format';
import {
  Card,
  CardTitle,
  StyledPercentage,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
  TableSkeleton,
} from '@/shared/components';
import { getImageMeuClubeGb } from '@/shared/assets';

type meuClubeGbCard = {
  isLoading: boolean;
  basePerMeuClubeGbLevel: meuClubeGbLevel[];
  css: CSS;
};

type meuClubeGbLevel = {
  active: number;
  activePercentage: number;
  base: number;
  basePercentage: number;
  code: keyof typeof meuClubeGbLevelNameMap;
};

export function MeuClubeGbCard({ isLoading, basePerMeuClubeGbLevel, css }: meuClubeGbCard) {
  return (
    <Card css={{ ...css }}>
      <CardTitle
        title="Meu Clube GB - FV"
        tooltip="Comparativo da base ativa pela quantidade de revendedores ativos, gerenciados pela Força de Vendas, por faixa no Meu Clube GB no ciclo selecionado."
      />
      <div style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
        <StyledTable sticky striped css={{ textAlign: 'right' }}>
          <thead>
            <StyledTr>
              <StyledTh css={{ minWidth: 200, '@tablet': { minWidth: 240 }, textAlign: 'left' }}>
                Classificação
              </StyledTh>
              <StyledTh css={{ width: '150px' }}>Base de abertura</StyledTh>
              <StyledTh>Ativas</StyledTh>
            </StyledTr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableSkeleton rows={9} />
            ) : (
              basePerMeuClubeGbLevel?.map(item => {
                const mappedName = meuClubeGbLevelNameMap[item.code];

                return (
                  <StyledTr key={item.code}>
                    <StyledTd css={{ textAlign: 'left' }}>
                      <Flex align="center">
                        <Hidden only={['mobile']}>
                          <Image
                            css={{
                              marginInlineEnd: '$3',
                              width: '40px',
                              height: '40px',
                              borderRadius: '100%',
                              backgroundColor: 'rgba(188, 188, 188, 0.2)',
                            }}
                            src={getImageMeuClubeGb(item.code)}
                            alt={`Meu Clube GB ${mappedName}`}
                            loading="lazy"
                          />
                        </Hidden>

                        <div>
                          {item.code !== 'novoRevendedor' && item.code !== 'indefinido' ? (
                            <Text size="bodyMediumStandard">{mappedName}</Text>
                          ) : null}

                          {item.code === 'novoRevendedor' && (
                            <>
                              Sem classificação
                              <Tooltip
                                showArrow
                                text="Esta classificação refere-se a novas REs que estão aguardando a sua classificação em alguma faixa do meu Clube GB."
                              >
                                <QuestionMarkCircleIcon
                                  size="14"
                                  color="$nonInteractiveAuxiliar"
                                  css={{ verticalAlign: '-2px', marginLeft: '$1' }}
                                />
                              </Tooltip>
                              <StyledPercentage align="left">Novos revendedores</StyledPercentage>
                            </>
                          )}
                          {item.code === 'indefinido' && (
                            <>
                              Sem classificação
                              <Tooltip
                                showArrow
                                text="Esta classificação refere-se a REs provenientes do Meu Club Boti que se estão aguardando a sua classificação no Meu Clube GB"
                              >
                                <QuestionMarkCircleIcon
                                  size="14"
                                  color="$nonInteractiveAuxiliar"
                                  css={{ verticalAlign: '-2px', marginLeft: '$1' }}
                                />
                              </Tooltip>
                              <StyledPercentage align="left">Em atualização</StyledPercentage>
                            </>
                          )}
                        </div>
                      </Flex>
                    </StyledTd>
                    <StyledTd>
                      {item?.base ? (
                        <>
                          {decimal.format(item.base)}
                          <StyledPercentage>{percent.format(item.basePercentage / 100)} do total</StyledPercentage>
                        </>
                      ) : (
                        '—'
                      )}
                    </StyledTd>
                    <StyledTd>
                      {item?.base ? (
                        <>
                          {decimal.format(item.active)}
                          <StyledPercentage>
                            {percent.format(item.activePercentage / 100)} dos {mappedName.toLowerCase()}
                          </StyledPercentage>
                        </>
                      ) : (
                        '—'
                      )}
                    </StyledTd>
                  </StyledTr>
                );
              })
            )}
          </tbody>
        </StyledTable>
      </div>
    </Card>
  );
}
