import { mix } from 'polished';
import { useMemo } from 'react';
import { PieLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { Flex, Text } from '@grupoboticario/flora-react';
import { PieChartIcon } from '@grupoboticario/flora-react-icons';
import { useResellersServiceNavigation } from '../context/Navigation';
import { useGetDashboardData } from '@/shared/hooks';

const scale = (baseColor, scaleSize) =>
  Array.from({ length: scaleSize }, (_, i) => mix(1 - i / (scaleSize - 1), baseColor, 'white'));

const optionTwo: Omit<ReactEChartsProps['option'], 'series'> = {
  title: {
    textStyle: {
      fontSize: 10,
      fontWeight: 'light',
      color: '#737373',
    },
    subtextStyle: {
      fontSize: 22,
      fontWeight: 'bold',
      color: '#0A0A0A',
    },
    top: '35%',
    left: 'center',
    bottom: 'center',
    right: 'center',
  },
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    show: false,
  },
};

function makeData(data, color) {
  return data?.columns?.map((item, index) => {
    return {
      value: item.resellers,
      name: item.name,
      itemStyle: { color: scale(color, data?.columns?.length + 1)[index] },
      id: item.code,
    };
  });
}
export function TabulationChartLevelThree() {
  const {
    parentIDs: { LVL2 },
    chartColor,
  } = useResellersServiceNavigation();
  const memoChartColor = useMemo(() => chartColor, [chartColor]);

  const { data, isLoading, fetchStatus } = useGetDashboardData({
    queryKey: 'callsDetails',
    path: '/calls/details',
    parent: LVL2,
    enabled: LVL2 !== null,
  });

  return (
    <Flex direction="column" align="center" justify="center" css={{ gridArea: 'level-three-chart', height: '100%' }}>
      {!data && fetchStatus === 'idle' ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          css={{
            minHeight: '352px',
            minWidth: '100%',
            borderRadius: '4px',
            backgroundImage:
              'repeating-linear-gradient(180deg, #bfbfbf, #bfbfbf 10px, transparent 10px, transparent 20px, #bfbfbf 20px), repeating-linear-gradient(270deg, #bfbfbf, #bfbfbf 10px, transparent 10px, transparent 20px, #bfbfbf 20px), repeating-linear-gradient(0deg, #bfbfbf, #bfbfbf 10px, transparent 10px, transparent 20px, #bfbfbf 20px), repeating-linear-gradient(90deg, #bfbfbf, #bfbfbf 10px, transparent 10px, transparent 20px, #bfbfbf 20px)',
            backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
            backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
            backgroundRepeat: 'no-repeat',
            pointerEvents: 'none',
          }}
        >
          <PieChartIcon size="64px" />
          <Text color="$nonInteractiveAuxiliar" css={{ marginBlockStart: '$12', marginInline: '$8' }} align="center">
            Selecione um tipo de atendimento para exibir mais detalhes
          </Text>
        </Flex>
      ) : (
        <>
          <ReactECharts
            loading={isLoading}
            style={{ minWidth: '100%', minHeight: '200px' }}
            option={{
              ...optionTwo,
              title: {
                ...(optionTwo.title as Record<string, never>),
                text: data?.parent?.name ?? '',
                subtext: data?.parent?.resellers ?? '',
              },
              series: {
                top: '-30%',
                left: '-20%',
                right: '-20%',
                bottom: '-30%',
                type: 'pie',
                radius: ['46%', '60%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                },
                itemStyle: {
                  borderColor: '#fff',
                  borderWidth: 2,
                },
                labelLine: {
                  show: false,
                },
                data: makeData(data, memoChartColor),
              },
            }}
          />
          <PieLegend columns={data?.columns} colors={scale(memoChartColor, data?.columns?.length + 1)} />
        </>
      )}
    </Flex>
  );
}
