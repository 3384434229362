import { FilterDrawer } from '@/features';
import { BaseProfileDashboard } from '@/features/BaseProfileDashboard';
import { PageTitle } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';

export function BaseProfile() {
  return (
    <>
      <Flex align="center" justify="space-between">
        <PageTitle>Perfil de Base</PageTitle>
        <FilterDrawer />
      </Flex>
      <BaseProfileDashboard />
    </>
  );
}
