import { styled } from '@grupoboticario/flora-react';
export const StyledSelectAllItems = styled('a', {
  marginInlineStart: 'auto',
  color: '$linkDefault',
  textDecoration: 'none',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    textDecoration: 'underline',
  },
});
