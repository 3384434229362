import { decimal, percent } from '@/shared/format';
import {
  Card,
  CardTitle,
  StyledPercentage,
  StyledTable,
  StyledTr,
  StyledTh,
  StyledTd,
  StyledInactivityLevelName,
  TableSkeleton,
} from '@/shared/components';
export function InactivityLevelCard({ isLoading, basePerInactivityLevel, css }) {
  const filteredBasePerInactivityLevel = basePerInactivityLevel?.filter(
    item => item.code !== 'A0' && item.code !== 'I7+',
  );
  const beginnings = basePerInactivityLevel?.filter(item => item.code === 'A0')[0];
  const reboots = basePerInactivityLevel?.filter(item => item.code === 'I7+')[0];

  return (
    <Card css={{ ...css }}>
      <CardTitle
        title="Nível de inatividade na abertura do ciclo - FV"
        tooltip="Comparativo da base ativa pela quantidade de revendedores ativos, gerenciados pela Força de Vendas, pelo nível de inatividade no ciclo selecionado."
      />
      <div style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
        <StyledTable sticky striped css={{ textAlign: 'right' }}>
          <thead>
            <StyledTr>
              <StyledTh css={{ minWidth: 160, textAlign: 'left' }}>Classificação</StyledTh>
              <StyledTh>Base de abertura</StyledTh>
              <StyledTh>Ativas</StyledTh>
            </StyledTr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableSkeleton rows={8} />
            ) : (
              <>
                {filteredBasePerInactivityLevel?.map(item => {
                  return (
                    <StyledTr key={item.code}>
                      <StyledTd css={{ textAlign: 'left' }}>
                        <StyledInactivityLevelName levelColor={item.code}>{item.code}</StyledInactivityLevelName>
                      </StyledTd>
                      <StyledTd>
                        {item?.base ? decimal.format(item.base) : '—'}
                        <StyledPercentage>
                          {item?.basePercentage ? `${percent.format(item.basePercentage / 100)} do total` : '—'}
                        </StyledPercentage>
                      </StyledTd>
                      <StyledTd>
                        {item?.active ? decimal.format(item.active) : '—'}
                        <StyledPercentage>
                          {item?.activePercentage
                            ? `${percent.format(item.activePercentage / 100)} dos ${item.code}`
                            : '—'}
                        </StyledPercentage>
                      </StyledTd>
                    </StyledTr>
                  );
                })}
                {beginnings?.code && (
                  <StyledTr>
                    <StyledTd css={{ textAlign: 'left' }}>
                      <StyledInactivityLevelName levelColor={beginnings.code}>Inícios</StyledInactivityLevelName>
                    </StyledTd>
                    <StyledTd>—</StyledTd>
                    <StyledTd>{beginnings?.active ? decimal.format(beginnings.active) : '—'}</StyledTd>
                  </StyledTr>
                )}
                {reboots?.code && (
                  <StyledTr>
                    <StyledTd css={{ textAlign: 'left' }}>
                      <StyledInactivityLevelName levelColor={reboots.code.replace('+', '')}>
                        Reinícios
                      </StyledInactivityLevelName>
                    </StyledTd>
                    <StyledTd>—</StyledTd>
                    <StyledTd>{reboots.active ? decimal.format(reboots.active) : '—'}</StyledTd>
                  </StyledTr>
                )}
              </>
            )}
          </tbody>
        </StyledTable>
      </div>
    </Card>
  );
}
